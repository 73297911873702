import React from 'react';
import classNames from 'classnames';
import MinistageSocialMediaIcon from './components/MinistageSocialMediaIcon';
import {
  TRACKING_CLASS_MINISTAGE_SOCIAL_MEDIA_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../../../shared/constants/tracking';
import { socialIconItems } from './constants';
import sections from '../../../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';

// Wrap the original component so we don't need to alter it.
const MinistageSocialMediaWrapper = (props) => (
  <MinistageSocialMediaParagraph {...props} />
);

const renderIcons = (icon, key) => (
  <li key={`ministage-social-media-icon-${key}`}>
    <MinistageSocialMediaIcon type={icon?.type} link={icon?.link} />
  </li>
);

const MinistageSocialMediaParagraph = ({
  ministageSocialMedia,
  isSplittedPageLayout,
}) => {
  if (!ministageSocialMedia) {
    return null;
  }

  return (
    <div
      className={classNames(
        TRACKING_CLASS_PARAGRAPH,
        TRACKING_CLASS_MINISTAGE_SOCIAL_MEDIA_PARAGRAPH,
        sections.Section,
        styles.Wrapper,
        { [styles.IsSplittedPageLayout]: isSplittedPageLayout },
      )}
    >
      <div
        className={classNames(
          {
            [sections.Container]: !isSplittedPageLayout,
            [styles.IsSplittedPageLayout]: isSplittedPageLayout,
          },
          styles.Container,
        )}
      >
        <div
          className={classNames(styles.Content, {
            [styles.IsSplittedPageLayout]: isSplittedPageLayout,
          })}
        >
          {ministageSocialMedia.headline && (
            <div className={styles.Headline}>
              {ministageSocialMedia.headline}
            </div>
          )}
          {ministageSocialMedia.subhead && (
            <p className={styles.Subhead}>{ministageSocialMedia.subhead}</p>
          )}
        </div>
        <ul
          className={classNames(styles.IconWrapper, {
            [styles.IsSplittedPageLayout]: isSplittedPageLayout,
          })}
        >
          {socialIconItems.map(renderIcons)}
        </ul>
      </div>
    </div>
  );
};

export default MinistageSocialMediaWrapper;
