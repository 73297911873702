import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { AsideComponent, AsideFactoryOptions, AsideProps } from './typings';

const asideFactory = ({ styles, content }: AsideFactoryOptions) => {
  const Aside: AsideComponent = ({
    props,
    pageLayoutType,
    scrollDirection,
  }: AsideProps): ReactElement => {
    const isCollapsed = scrollDirection === 'down';
    const children = content({ props, pageLayoutType });

    if (!children || !Array.isArray(children) || !children.length) {
      return null;
    }

    return (
      <div className={styles.Wrapper} data-testid="wrapper">
        {children.map((child, index) => {
          const isLastElement = index === children.length - 1;
          return (
            <div
              key={`content-aside-item-${index}`}
              id={`content-aside-item-${index}`}
              className={classNames({
                [styles.Sticky]: isLastElement,
                [styles.StickyOnScroll]: isLastElement && isCollapsed,
              })}
            >
              {child}
            </div>
          );
        })}
      </div>
    );
  };

  return Aside;
};

export default asideFactory;
