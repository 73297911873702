import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import Icon from '../../../../components/Icon';
import SearchInput from '../../components/SearchInput';
import FilterDropdown from './components/FilterDropdown';
import SortingDropdown from './components/SortingDropdown';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { ActionButtonsProps } from './typings';

const ActionButtons = ({
  query,
  industryList,
  stateList,
  location,
  setActions,
}: ActionButtonsProps) => {
  const [areOptionsVisible, setOptionsVisible] = useState(false);
  const queryCopy = JSON.parse(JSON.stringify(query));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams({});

  const isFilterActive = Object.keys(queryCopy).length > 0;
  let isWomenFilterActive = !!queryCopy.filterByGender;
  let isNewcomerFilterActive = !!queryCopy.filterByStatus;

  const filterButtonsJsx = (
    <>
      <ButtonWithLoading
        ariaLabel="filterForWoman"
        size="small"
        variant="secondary"
        addClass={classNames({ [styles.Disabled]: !isWomenFilterActive })}
        onClick={() => {
          event.preventDefault();
          isWomenFilterActive = !isWomenFilterActive;
          setActions('frauen');
          if (queryCopy.filterByGender) {
            delete queryCopy['filterByGender'];
            setSearchParams(
              { ...queryCopy },
              {
                state: { preventScroll: true },
              },
            );
          }
        }}
        mobileFullWidth
      >
        Frauen
      </ButtonWithLoading>

      <ButtonWithLoading
        ariaLabel="filterForNewcomer"
        size="small"
        variant="secondary"
        addClass={classNames({ [styles.Disabled]: !isNewcomerFilterActive })}
        onClick={() => {
          event.preventDefault();
          isNewcomerFilterActive = !isNewcomerFilterActive;
          setActions('neu', 'filterByStatus');
          if (queryCopy.filterByStatus) {
            delete queryCopy['filterByStatus'];
            setSearchParams(
              { ...queryCopy },
              {
                state: { preventScroll: true },
              },
            );
          }
        }}
        mobileFullWidth
      >
        Neu
      </ButtonWithLoading>
    </>
  );

  return (
    <div className={classNames(styles.ButtonWrapper, 'hide-on-print')}>
      <div
        className={classNames(grid.HiddenSmDown, styles.FilterButtonWrapper)}
      >
        {filterButtonsJsx}
        <FilterDropdown
          setActions={setActions}
          industryList={industryList}
          stateList={stateList}
          query={queryCopy}
          location={location}
        />
      </div>
      <SearchInput setActions={setActions} />
      <div>
        {isFilterActive && (
          <ButtonWithLoading
            ariaLabel="resetFilter"
            size="small"
            variant="tertiary"
            onClick={() => {
              event.preventDefault();
              Object.keys(queryCopy)
                .filter((key) => key)
                .forEach((filter) => {
                  delete queryCopy[filter];
                });
              setSearchParams(
                { ...queryCopy },
                {
                  state: { preventScroll: true },
                },
              );
            }}
          >
            Zurücksetzen
          </ButtonWithLoading>
        )}
      </div>
      <div className={grid.HiddenSmUp}>
        <ButtonWithLoading
          ariaLabel="resetFilter"
          size="small"
          variant="primary"
          addClass={classNames({ [styles.Hidden]: areOptionsVisible })}
          iconTypeLeft="IconSliders"
          onClick={() => setOptionsVisible(!areOptionsVisible)}
          mobileFullWidth
        >
          {`Filtern & Sortieren`}
        </ButtonWithLoading>
        <div className={classNames({ [styles.Hidden]: !areOptionsVisible })}>
          <div className={styles.OptionsWrapper}>
            <div className={styles.OptionsHeader}>
              <span
                className={styles.HeaderTitle}
              >{`Filtern & Sortieren`}</span>
              <span
                className={styles.OptionCloseIcon}
                tabIndex={0}
                role="button"
                aria-label="Close"
                onClick={() => setOptionsVisible(false)}
                onKeyUp={() => () => setOptionsVisible(false)}
              >
                <Icon type="IconXMark" />
              </span>
            </div>
            <div>
              <div className={styles.ActionDescription}>Sortieren nach</div>
              <SortingDropdown setActions={setActions} query={queryCopy} />
            </div>
            <div className={styles.ActionDescription}>Filtern nach</div>
            <div className={styles.FilterButtonWrapper}>{filterButtonsJsx}</div>
            <FilterDropdown
              setActions={setActions}
              industryList={industryList}
              stateList={stateList}
              query={queryCopy}
              location={location}
            />
            {isFilterActive && (
              <ButtonWithLoading
                ariaLabel="resetFilter"
                size="small"
                variant="tertiary"
                onClick={() => {
                  event.preventDefault();
                  Object.keys(queryCopy)
                    .filter((key) => key)
                    .forEach((filter) => {
                      delete queryCopy[filter];
                    });
                  setSearchParams(
                    { ...queryCopy },
                    {
                      state: { preventScroll: true },
                    },
                  );
                }}
              >
                Zurücksetzen
              </ButtonWithLoading>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
