import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { assembleAkamaiImgUrl } from '../../../../../../../common/components/Picture/helpers';
import { getRestrictedClassName } from '../../../../../../../shared/helpers/withHelmet';
import { formatWealthNumber } from '../../../Ranking/helpers';
import locationStateSelector from '../../../../../../../shared/selectors/locationStateSelector';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import EditButtons from '../../../../components/EditButtons';
import Helmet from '../../../../components/Helmet';
import Icon from '../../../../components/Icon';
import Paragraphs from '../../../../components/Paragraphs';
import TextParagraph from '../../../../components/Paragraphs/components/TextParagraph';
import RelatedToContent from '../../../../components/RelatedToContent';
import IconTrend from '../../../Ranking/components/IconTrend';
import TrendChart from '../../../Ranking/components/TrendChart';
import { getTitle } from '../Listing';
import { RESTRICTION_STATUS_PAID } from '../../../../../../../shared/constants/content';
import { STYLE_1X1_660 } from '../../../../../../../shared/constants/images';
import { LANDING_PAGE_TYPE } from '../../../LandingPage/constants';
import {
  PERSON_PAGE_SIZE,
  RANKING_TYPE_RICHEST,
  SCREEN_LABEL,
} from '../../constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { PersonProps } from '../../typings';

type PersonPropsInner = PersonProps;

const ensureChartData = (data: RankingsEdge[]) => {
  return data.map(({ node }) => {
    return {
      x: node?.ranking?.year.toString(),
      y: parseFloat(node.rankingValue),
    };
  });
};

const PersonDetail = ({ person, location }: PersonPropsInner) => {
  const clientUrl = useSelector(
    (state) => locationStateSelector(state).clientUrl,
  );

  if (!person || !person.name) {
    return null;
  }

  const mostRichList = person?.rankings?.edges?.filter(
    (ranking) => ranking?.node?.ranking?.rankingType === RANKING_TYPE_RICHEST,
  );

  const ensuredChartData = ensureChartData(mostRichList);

  const heroImage =
    (Array.isArray(person?.heroImageBody) &&
      person?.heroImageBody.length > 0 &&
      // @ts-ignore wrong paragraphInterface typing in autogenerated typings
      person.heroImageBody[0]?.image?.file) ||
    null;

  // hide the image, if the relativeOriginPath contains a fallback image
  const isHeadless =
    (heroImage?.relativeOriginPath &&
      heroImage?.relativeOriginPath.includes('handelszeitung_fallback', 1)) ||
    (heroImage?.relativeOriginPath &&
      heroImage?.relativeOriginPath.includes('beobachter-fallback', 1)) ||
    false;

  const organizationPositions = [];
  const personPositionsEdges =
    (Array.isArray(person?.personPositions?.edges) &&
      person.personPositions.edges.length > 0 &&
      person?.personPositions?.edges) ||
    null;

  const title = `mehr zu: ${person.name}` || 'mehr zu dieser Person';

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: person?.firstName || '',
    image:
      (heroImage?.relativeOriginPath &&
        assembleAkamaiImgUrl({
          relativeOriginPath: heroImage?.relativeOriginPath,
          width: 350,
          height: 350,
          focalPointX: heroImage?.focalPointX,
          focalPointY: heroImage?.focalPointY,
          clientUrl,
        })) ||
      '',
    brand: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name:
        (personPositionsEdges &&
          personPositionsEdges.map((item) => item.node.organization.title)) ||
        '',
    },

    jobTitle:
      (personPositionsEdges &&
        personPositionsEdges.map((item) => item.node.position)) ||
      '',

    sameAs: person?.moneyhousePreferredUri || '',
    isAccessibleForFree: true,
    hasPart: undefined,
  };

  if ([RESTRICTION_STATUS_PAID].includes(person?.restrictionStatus)) {
    jsonLd.isAccessibleForFree = false;

    jsonLd.hasPart = [
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: `.${getRestrictedClassName(person?.__typename)}`,
      },
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: '.paywall-person-text',
      },
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: '.paywall-person-paragraphs',
      },
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: '.paywall-person-listing',
      },
    ];
  }

  const script = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonLd),
    },
  ];

  if (personPositionsEdges) {
    for (let i = 0; i < personPositionsEdges.length; ++i) {
      organizationPositions.push(
        personPositionsEdges[i].node?.organization?.organizationPositions,
      );
    }
  }

  return (
    <div className={classNames(SCREEN_LABEL, styles.Wrapper)}>
      <EditButtons
        editContentUri={person.editContentUri}
        editRelationUri={person.editRelationUri}
        cloneContentUri={person.cloneContentUri}
      />
      <Helmet script={(person?.moneyhousePreferredUri && script) || []} />
      <div className={styles.HeaderWrapper}>
        <div className={grid.Container}>
          <Breadcrumbs
            pageUrl={person.preferredUri}
            items={person.activeMenuTrail}
          />
          <div className={grid.Row}>
            <div
              className={classNames(
                grid.ColXs24,
                grid.ColOffsetMd2,
                grid.ColMd20,
              )}
            >
              <h1 className={styles.Title}>{getTitle(RANKING_TYPE_RICHEST)}</h1>
              <div className={styles.BackWrapper}>
                <Link
                  className={styles.BackLink}
                  path={`/bilanz/die-300-reichsten-${
                    (mostRichList[mostRichList.length - 1]?.node?.ranking
                      ?.year &&
                      `${mostRichList[mostRichList.length - 1]?.node?.ranking
                        ?.year}`) ||
                    ''
                  }`}
                >
                  <Icon type="IconChevronLeft" /> Zurück zur Liste
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={grid.Container}>
        <div className={grid.Row}>
          <div
            className={classNames(
              grid.ColXs24,
              grid.ColOffsetMd2,
              grid.ColMd20,
            )}
          >
            <div className={grid.Row}>
              <div className={classNames(grid.ColXs24, grid.ColSm10)}>
                {!isHeadless && heroImage?.relativeOriginPath && (
                  <Picture
                    relativeOrigin={heroImage?.relativeOriginPath}
                    focalPointX={heroImage?.focalPointX}
                    focalPointY={heroImage?.focalPointY}
                    className={styles.Image}
                    alt={heroImage?.alt || ''}
                    style_320={STYLE_1X1_660}
                  />
                )}
                <div className={styles.PersonTitle}>{person.name}</div>
                <p className={styles.ListTitle}>
                  Vermögen
                  {(mostRichList[mostRichList.length - 1]?.node?.ranking
                    ?.year &&
                    ` im Jahr ${mostRichList[mostRichList.length - 1]?.node
                      ?.ranking?.year}`) ||
                    ''}
                </p>
                <div className={styles.RankingValue}>
                  {formatWealthNumber(
                    mostRichList[mostRichList.length - 1]?.node?.rankingValue,
                  )}
                  {mostRichList[mostRichList.length - 1]?.node?.rankingTrend !==
                    'new' &&
                    mostRichList[mostRichList.length - 1]?.node
                      ?.rankingTrend !== 'returnee' && (
                      <IconTrend
                        addClass={styles.Icon}
                        rankingTrend={
                          mostRichList[mostRichList.length - 1]?.node
                            ?.rankingTrend
                        }
                      />
                    )}
                </div>
                <div>
                  <div className={styles.List}>
                    <div>
                      <p className={styles.ListTitle}>Rang</p>
                      <p className={styles.ListItem}>
                        {mostRichList[mostRichList.length - 1]?.node
                          ?.rankingPosition || <>&ndash;</>}
                        {(mostRichList[mostRichList.length - 1]?.node
                          ?.rankingTrend === 'new' ||
                          mostRichList[mostRichList.length - 1]?.node
                            ?.rankingTrend === 'returnee') && (
                          <IconTrend
                            rankingTrend={
                              mostRichList[mostRichList.length - 1]?.node
                                ?.rankingTrend
                            }
                            addClass={styles.IconReturnee}
                          />
                        )}
                      </p>
                    </div>
                    <div>
                      <p className={styles.ListTitle}>Branche</p>
                      <p className={styles.ListItem}>
                        {mostRichList[mostRichList.length - 1]?.node
                          ?.rankingIndustry || <>&ndash;</>}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.StateWrapper}>
                  <p className={styles.ListTitle}>Kanton</p>
                  <p className={styles.ListItem}>
                    {mostRichList[mostRichList.length - 1]?.node
                      ?.rankingState || <>&ndash;</>}
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  grid.ColXs24,
                  grid.ColOffsetMd1,
                  grid.ColSm14,
                  grid.ColMd13,
                )}
              >
                <div className={classNames('paywall-person-paragraphs')}>
                  {typeof person.text !== 'undefined' && (
                    <div className={styles.TextWrapper}>
                      <TextParagraph
                        addClass={classNames('paywall-person-text')}
                        textParagraph={{ text: person.text }}
                      />
                    </div>
                  )}
                  <Suspense>
                    <div className={classNames(styles.ChartWrapper)}>
                      <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                        <TrendChart
                          x={ensuredChartData.map((item) => item.x)}
                          y={ensuredChartData.map((item) => item.y)}
                          title="Entwicklung des Vermögens"
                        />
                      </div>
                    </div>
                  </Suspense>
                  <Paragraphs
                    pageBody={person.content}
                    colStyle={grid.ColXs24}
                    origin={LANDING_PAGE_TYPE}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RelatedToContent
        title={person.name}
        relatedToTitle={title}
        location={location}
      />
    </div>
  );
};

const PersonWrapper = compose(
  withHelmet({
    getNode: (mapProps) => mapProps.person || null,
    getNodesCount: (mapProps) => mapProps.person?.personArticles?.count || 0,
    pageSize: PERSON_PAGE_SIZE,
  }),
)(PersonDetail);

export default PersonWrapper;
