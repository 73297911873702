export const PERSON_PAGE_TYPE = 'person';
export const SCREEN_LABEL = 'Person';
export const PERSON_PAGE_SIZE = 13;

export const RANKING_TYPE_RICHEST = '300_reichste';
export const RANKING_TYPE_TOP_BANKER = 'top_banker';
export const RANKING_TYPE_WHO_IS_WHO = 'who_is_who';
export const RANKING_TYPE_DIGITAL_SHAPERS = 'digital_shapers';
export const RANKING_TYPE_YOUNG_RICHEST = 'junge_reichste';
export const RANKING_TYPE_YOUNG_RICHEST_INHERITANCE = 'junge_reichste_erben';
export const RANKING_TYPE_YOUNG_RICHEST_STARS = 'junge_reichste_stars';
export const RANKING_TYPE_YOUNG_RICHEST_ENTREPRENEUR =
  'junge_reichste_unternehmer';

export const PERSON_DETAIL_LEGACY = 'person-detail-legacy';
export const PERSON_DETAIL = 'person-detail';
