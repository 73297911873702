/* istanbul ignore file */

import clientFactory from '../../../common/entry/client/factory';
import App from '../../screens/App';
import RaschProviders from '../../screens/App/components/RaschProviders';
import { register } from '../../../shared/serviceWorker';
import { displayInfoToast } from '../../screens/App/components/Toast';
import generateApolloCache from '../../../shared/generateApolloCache';
import { configureClientStore } from '../../shared/configureStore';

const isPWApp = Boolean(
  (!__TESTING__ &&
    __CLIENT__ &&
    (global?.navigator?.['standalone'] ||
      window.matchMedia('(display-mode: standalone)').matches)) ||
    false,
);

// Find the DOM node generated by the server
const mountNode: HTMLElement = document.getElementById('app');

const preRender = (): void => {
  register({
    onUpdate: (registration, callback) => {
      const registrationWaiting = registration.waiting;
      if (registrationWaiting && isPWApp) {
        displayInfoToast(
          'Eine neue Version ist verfügbar. ',
          {
            text: 'Aktualisieren',
            onClick: () => {
              callback(registrationWaiting, {
                type: 'SKIP_WAITING',
              });
            },
          },
          'sw-update-toast',
        );
      }
    },
  });
};

const client = clientFactory({
  RaschProviders,
  AppRoutes: App,
  configureClientStore,
  mountNode,
  preRender,
  generateApolloCache,
});

// Run client application
client();
