import React, { ReactElement, memo } from 'react';
import createComponentSwitch from '../../../../../shared/decorators/componentSwitch';
import PersonDetail from './components/PersonDetail';
import PersonDetailLegacy from './components/PersonDetailLegacy';
import { PERSON_DETAIL, PERSON_DETAIL_LEGACY } from './constants';
import { PersonProps } from './typings';

const Switch = createComponentSwitch({
  [PERSON_DETAIL_LEGACY]: PersonDetailLegacy,
  [PERSON_DETAIL]: PersonDetail,
});

const Person = (props): ReactElement => {
  return <Switch component={props.component} {...props} />;
};

export default memo<PersonProps>(Person);
