import loginFormFactory from '../../../../../../../common/components/LoginForm/factory';
import styles from './styles.legacy.css';

export default loginFormFactory({
  styles: {
    LoginFormWrapper: styles.LoginFormWrapper,
    Button: styles.Button,
    Message: styles.Message,
  },
});
