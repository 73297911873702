import React, { ReactElement } from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import withHelmet from '../../../../shared/decorators/withHelmet';
import Breadcrumbs from '../../components/Breadcrumbs';
import EditButtons from '../../components/EditButtons';
import JobChecker from '../../components/JobChecker';
import OrganizationsInNews from '../../components/OrganizationsInNews';
import RelatedContent from '../../components/RelatedContent';
import SalaryCheckerPerBranch from '../../components/SalaryCheckerPerBranch';
import Timeline from '../../components/Timeline';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../../components/Pager';
import { GRID_LAYOUT_TEASER_3X2_FIRST } from '../../components/TeaserGrid/gridConfigs/constants';
import {
  BRANCH_ANCHOR_MORE_ABOUT,
  BRANCH_ID_MORE_ABOUT,
  BRANCH_PAGE_SIZE,
} from './constants';
import sections from '../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import { BranchProps } from './typings';

const SCREEN_LABEL = 'Branch';

type BranchDetailPropsInner = BranchProps;

const BranchDetail = ({
  branch,
  page,
}: BranchDetailPropsInner): ReactElement => {
  if (!branch) {
    return null;
  }

  const hasRelatedArticles =
    (branch.relatedArticles &&
      branch.relatedArticles.edges &&
      branch.relatedArticles.edges.length > 0) ||
    false;

  const title =
    (branch.title && `mehr zu: ${branch.title}`) || 'mehr zu dieser Branche';

  return (
    <div className={classNames(SCREEN_LABEL, styles.Wrapper)}>
      <EditButtons
        editContentUri={branch.editContentUri}
        editRelationUri={branch.editRelationUri}
        cloneContentUri={branch.cloneContentUri}
      />

      <div className={styles.OuterWrapper}>
        <div className={styles.HeaderBackground} />
        <div className={classNames(sections.Container, styles.InnerWrapper)}>
          {branch.activeMenuTrail && (
            <Breadcrumbs
              pageUrl={branch.preferredUri}
              items={branch.activeMenuTrail}
            />
          )}
          <div>
            <h1 className={styles.Title}>
              <p className={styles.ShortTitle}>Branche</p>
              {branch.title}
            </h1>
          </div>
        </div>
      </div>
      <div className={sections.Container}>
        <OrganizationsInNews organizations={branch.relatedOrganizations} />

        {hasRelatedArticles && (
          <Timeline
            relatedArticles={
              (branch.relatedArticles &&
                branch.relatedArticles.edges && {
                  edges: branch.relatedArticles.edges.slice(0, 5),
                }) ||
              null
            }
            link={BRANCH_ANCHOR_MORE_ABOUT}
          />
        )}

        <JobChecker nid={branch.nid ? parseInt(branch.nid, 10) : null} />
        <SalaryCheckerPerBranch branch={branch} />
      </div>

      {branch?.nativeAdvertisings?.edges?.length > 0 && (
        <>
          <RelatedContent
            //@ts-ignore
            teaserGridLayout={GRID_LAYOUT_TEASER_3X2_FIRST}
            gridOptionType={'dotted'}
            title={`Mehr aus der Branche`}
            relatedContent={branch.nativeAdvertisings}
          />
        </>
      )}
      {hasRelatedArticles && (
        <div id={BRANCH_ID_MORE_ABOUT}>
          <RelatedContent
            //@ts-ignore
            teaserGridLayout={GRID_LAYOUT_TEASER_3X2_FIRST}
            gridOptionType={'dotted'}
            title={title}
            relatedContent={branch.relatedArticles}
            page={page}
            pageSize={BRANCH_PAGE_SIZE}
            itemCount={branch.relatedArticles.count}
            hasContainer={true}
          />
          <Pager
            itemsCount={branch.relatedArticles.count || 0}
            itemsPerPage={BRANCH_PAGE_SIZE}
            currentPage={page}
            component={PAGER_TYPE_PAGE_LOADER}
            anchorScrollId={BRANCH_ID_MORE_ABOUT}
          />
        </div>
      )}
    </div>
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps) => mapProps.branch || null,
    getNodesCount: (mapProps) => mapProps.branch?.relatedArticles?.count || 0,
    pageSize: BRANCH_PAGE_SIZE,
  }),
)(BranchDetail);
