import { ROUTE_BIL } from '../../constants';

export const LANDING_PAGE_TYPE = 'landing-page';
export const LANDING_PAGE_TYPE_SV = 'landing-page-sv';
export const LANDING_PAGE_TYPE_SV_HOME = 'landing-page-sv-home';
export const LANDING_PAGE_TYPE_HZB = 'landing-page-hzb';
export const LANDING_PAGE_TYPE_HZB_HOME = 'landing-page-hzb-home';
export const LANDING_PAGE_TYPE_BILANZ = 'landing-page-bilanz';
export const LANDING_PAGE_GRID_PAGE_SIZE_HOME = 30;
export const LANDING_PAGE_GRID_PAGE_SIZE = 11;
export const LANDING_PAGE_VIDEO_BLOG_GRID_PAGE_SIZE = 12;
export const LANDING_PAGE_TITLE_SPECIAL = 'Specials';
export const TEASER_LAYOUT_IS_SPECIAL = 'special';
export const LANDING_PAGE_BILANZ_HOME = ROUTE_BIL;
export const LANDING_PAGE_SV_HOME = '/insurance';
export const LANDING_PAGE_HZB_HOME = '/banking';
export const PAGER_ANCHOR_SCROLL_ID = 'page';
