// filter by: Woman | Newcomer | Returnee | Industry
export const filterRanking = (
  rankingList: RankingsEdge[],
  filterBy: string,
) => {
  const decodedFilterBy = decodeURI(filterBy);
  const filteredList = rankingList.filter(
    ({
      node: {
        person = null,
        rankingIndustry = null,
        rankingState = null,
        rankingTrend = null,
      },
    }: RankingsEdge) => {
      // filter by: new commer
      if (decodedFilterBy === 'neu') {
        if (rankingTrend === 'new') {
          return true;
        }
      }

      // filter by: returnee
      if (decodedFilterBy === 'returnee') {
        if (rankingTrend === 'returnee') {
          return true;
        }
      }

      // filter by: woman
      if (decodedFilterBy === 'frauen') {
        if (person?.isWoman) {
          return true;
        }
      }

      // filter by: industry
      if (
        rankingIndustry === decodedFilterBy ||
        rankingIndustry.includes(decodedFilterBy) ||
        (rankingIndustry.split(',').length > 1 &&
          rankingIndustry.split(',').some((splittedIndustryVal) => {
            return (
              splittedIndustryVal.toLowerCase().trim() ===
              decodedFilterBy.toLowerCase().trim()
            );
          }))
      ) {
        return true;
      }

      // filter by: state
      if (
        rankingState === decodedFilterBy ||
        rankingState.toLowerCase().trim() ===
          decodedFilterBy.toLowerCase().trim()
      ) {
        return true;
      }
    },
  );

  return filteredList;
};

// sort by: rank | name | networth
export const sortRanking = (
  list: RankingsEdge[],
  sortBy: string,
  direction: string = 'asc',
) => {
  // only one item in the list. no need to sort
  if (list?.length < 2) return list;

  switch (decodeURI(sortBy)) {
    case 'rang':
      return list?.sort((a, b) => {
        return (
          (direction === 'desc' &&
            b.node.rankingPosition - a.node.rankingPosition) ||
          a.node.rankingPosition - b.node.rankingPosition
        );
      });

    case 'name':
      return list?.sort((a, b) => {
        if (a.node.person?.name < b.node.person?.name) {
          return (direction === 'asc' && -1) || 1;
        }

        if (a.node.person?.name > b.node.person?.name) {
          return (direction === 'asc' && 1) || -1;
        }
        return 0;
      });

    case 'vermögen':
      return list?.sort((a, b) => {
        const aValue = Number(a.node.rankingValue);
        const bValue = Number(b.node.rankingValue);
        return (direction === 'desc' && bValue - aValue) || aValue - bValue;
      });

    case 'branche':
      return list?.sort((a, b) => {
        if (a.node.rankingIndustry < b.node.rankingIndustry) {
          return (direction === 'asc' && -1) || 1;
        }

        if (a.node.rankingIndustry > b.node.rankingIndustry) {
          return (direction === 'asc' && 1) || -1;
        }
        return 0;
      });

    case 'kanton':
      return list?.sort((a, b) => {
        if (a.node.rankingState < b.node.rankingState) {
          return (direction === 'asc' && -1) || 1;
        }

        if (a.node.rankingState > b.node.rankingState) {
          return (direction === 'asc' && 1) || -1;
        }
        return 0;
      });

    default:
      return list;
  }
};

export const getSearchResults = (list: RankingsEdge[], searchTerm: string) => {
  if (!searchTerm) {
    return list;
  }

  return list.filter((item) => {
    if (!item.node) return list;
    const nodeForSearch = encodeURI(item?.node?.person?.name);
    const searchTermLowerCase = searchTerm?.toLowerCase?.();

    if (nodeForSearch?.toLocaleLowerCase?.()?.includes?.(searchTermLowerCase)) {
      return item;
    }
  });
};

export const formatWealthNumber = (input: any): string => {
  const inputNumber = Number(input);
  if (!input || Number.isNaN(inputNumber) || inputNumber === 0) {
    if (inputNumber !== 0 && !Number.isNaN(inputNumber)) {
      return inputNumber.toString();
    }
    if (typeof input === 'string') {
      return input?.trim?.();
    }
    return input;
  }
  if (inputNumber < 1_000_000) {
    return new Intl.NumberFormat('de-CH', {}).format(inputNumber);
  }
  if (inputNumber < 1_000_000_000) {
    return `${Math.round(inputNumber / 1_000_000)} Mio.`;
  }
  let result = (inputNumber / 1_000_000_000).toFixed(1).replace('.', ',');
  if (result[result.length - 1] === '0') {
    result = result.slice(0, -2);
  }
  return `${result} Mrd.`;
};
