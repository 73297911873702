/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_GROUP_HZ } from '../../../../../shared/constants/publications';
import { GET_KEYWORD_LISTING } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ params: { searchString = 'A' } }) => ({
    query: GET_KEYWORD_LISTING,
    variables: {
      searchString: searchString && `${searchString.toUpperCase()}`,
      publication: PUBLICATION_GROUP_HZ,
    },
    skip: !!(searchString && searchString.length > 1),
  }),
};
