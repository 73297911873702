// IMAGE
export const TEASER_IMAGE_IDENTIFIER = 'teaser-image';
export const TEASER_PLACEHOLDER_PATH =
  '/media/field_image/2017-10/placeholder.gif';

// CONFIG
export const TEASER_LEAD_LENGTH = 147;
export const TEASER_XS_LEAD_LENGTH = 120;
export const TEASER_LEAD_LENGTH_SEATCHANGE = 197;
export const TEASER_LEAD_SUFFIX_TEXT = '...';
export const TEASER_TITLE_LENGTH = 120;
export const TEASER_SMALL_TEXT_TITLE_LENGTH = 55;

// CTA
export const TEASER_CTA_SPECIAL = 'zum Special';
export const TEASER_CTA_CHANNEL = 'zum Channel';
export const TEASER_CTA_DOSSIER = 'zum Dossier';

// TEASER HERO
export const TEASER_HERO_DEFAULT_IDENTIFIER = 'teaser-hero-default';
export const TEASER_HERO_OPINION_IDENTIFIER = 'teaser-hero-opinion';
export const TEASER_HERO_BRANDREPORT_IDENTIFIER = 'teaser-hero-brand-report';

// TEASER L
export const TEASER_L_DEFAULT_IDENTIFIER = 'teaser-l-default';
export const TEASER_L_OPINION_IDENTIFIER = 'teaser-l-opinion';
export const TEASER_L_BRANDREPORT_IDENTIFIER = 'teaser-l-brand-report';

// TEASER M
export const TEASER_M_DEFAULT_IDENTIFIER = 'teaser-m-default';
export const TEASER_M_OPINION_IDENTIFIER = 'teaser-m-opinion';
export const TEASER_M_BRANDREPORT_IDENTIFIER = 'teaser-m-brand-report';

// TEASER SM
export const TEASER_SM_DEFAULT_IDENTIFIER = 'teaser-sm-default';
export const TEASER_SM_OPINION_IDENTIFIER = 'teaser-sm-opinion';
export const TEASER_SM_BRANDREPORT_IDENTIFIER = 'teaser-sm-brand-report';

// TEASER S
export const TEASER_S_DEFAULT_IDENTIFIER = 'teaser-s-default';
export const TEASER_S_OPINION_IDENTIFIER = 'teaser-s-opinion';
export const TEASER_S_BRANDREPORT_IDENTIFIER = 'teaser-s-brand-report';

//TEASER XS
export const TEASER_XS_DEFAULT_IDENTIFIER = 'teaser-xs-default';
export const TEASER_XS_OPINION_IDENTIFIER = 'teaser-xs-opinion';
export const TEASER_XS_BRANDREPORT_IDENTIFIER = 'teaser-xs-brand-report';

// TEASER XXS
export const TEASER_XXS_DEFAULT_IDENTIFIER = 'teaser-xxs-default';

// OTHER TEASERS
export const TEASER_RANKING_LIST_IDENTIFIER = 'teaser-ranking-list';
export const TEASER_SPONSOR_IDENTIFIER = 'teaser-sponsor-company';
export const TEASER_BOOKMARK_IDENTIFIER = 'teaser-bookmark';

//remove after adjusting hz-badge
export const TEASER_MAIN_IDENTIFIER = 'teaser-main';
export const TEASER_SECONDARY_IDENTIFIER = 'teaser-secondary';

export const TEASER_LAYOUT_SUMMARY = 'teaser-summary';
