/**
 * Grid Layouts
 */
export const GRID_LAYOUT_LANDINGPAGE_DEFAULT = 'landingPageDefault';
export const GRID_LAYOUT_CONTENT_STAGE = 'contentStage';
export const GRID_LAYOUT_CONTENT_STAGE_FIRST = 'contentStageFirst';
export const GRID_LAYOUT_LATEST_HEADLESS = 'latestHeadlessStories';
export const GRID_LAYOUT_ENTITY_QUEUE_HOME = 'entityQueueHome';
export const GRID_LAYOUT_ENTITY_QUEUE_SV_HOME = 'entityQueueSvHome';
export const GRID_LAYOUT_ENTITY_QUEUE_HZB_HOME = 'entityQueueHZBHome';
export const GRID_LAYOUT_ENTITY_QUEUE_DEFAULT = 'entityQueueDefault';
export const GRID_LAYOUT_ENTITY_QUEUE_SWISS_INSURANCE = 'entityQueueSv';
export const GRID_LAYOUT_ENTITY_QUEUE_HZB = 'entityQueueSv'; // TODO: change latter to HZB Name
export const GRID_LAYOUT_ENTITY_QUEUE_ADDITIONAL_PUBLICATIONS =
  'entityQueueAdditionalPublications';
export const GRID_LAYOUT_ENTITY_QUEUE_ADDITIONAL_PUBLICATIONS_FIRST =
  'entityQueueAdditionalPublicationsFirst';
export const GRID_LAYOUT_MENU_OVERLAY = 'menuOverlay';
export const GRID_LAYOUT_TEASER_3X2 = 'teaser3x2';
export const GRID_LAYOUT_TEASER_3X2_FIRST = 'teaser3x2First';
export const GRID_LAYOUT_TEASER_3X3 = 'teaser3x3';
export const GRID_LAYOUT_TEASER_3X4 = 'teaser3x4';
export const GRID_LAYOUT_TEASER_3X4_NO_ADS = 'teaser3x4NoAds';
export const GRID_LAYOUT_TEASER_3X6 = 'teaser3x6';
export const GRID_LAYOUT_TEASER_4X4 = 'teaser4x4';
export const GRID_LAYOUT_TEASER_RANKING = 'teaserRanking';
export const GRID_LAYOUT_VIDEO_BLOGS = 'videoBlogs';
export const GRID_LAYOUT_TEASER_RANKING_LIST = 'teaserRankingList';
export const GRID_LAYOUT_RECOMMENDATIONS = 'teaserRecommendations';
export const GRID_LAYOUT_SM = 'teaserSM';
export const GRID_LAYOUT_ORGANIZATION_IN_NEWS_3X2 = 'organizationInNews3x2';
export const GRID_LAYOUT_TEAM_MEMBERS = 'teamMembers';
