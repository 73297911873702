import React, { cloneElement } from 'react';
import classNames from 'classnames';
import { RestrictedContentProps } from './typing';
import styles from './styles.legacy.css';

const isUpperCase = (word: string): boolean => {
  return word[0] === word[0]?.toUpperCase();
};

const shuffleWord = (word: string): string => {
  return [...word].sort(() => Math.random() - 0.5).join('');
};

const capitalizeWord = (word: string): string => {
  return [...word][0]?.toUpperCase() + [...word].slice(1).join('');
};

const shuffleText = (text: string): string => {
  const words = text.split(' ');
  const sentence = [];
  words.forEach((word) => {
    let shuffled = shuffleWord(word).toLowerCase();
    if (isUpperCase(word)) {
      shuffled = capitalizeWord(shuffled);
    }
    sentence.push(shuffled);
  });
  return sentence.join(' ');
};

const obfuscateNode = (node: React.ReactElement, blur = false) => {
  const children = node?.props?.children;
  const className = `${node?.props?.className} ${
    blur ? styles.Wrapper : ''
  }`.trim();
  if (typeof children === 'string') {
    return (
      <node.type {...node.props} className={className}>
        {shuffleText(children)}
      </node.type>
    );
  }
  const jsxElements = node?.props?.children?.map?.((childNode: any) =>
    obfuscateNode(childNode, false),
  );
  return cloneElement(
    <node.type {...node.props} className={className}>
      {jsxElements}
    </node.type>,
  );
};

/**
 * Components that blurs and obfuscates its content.
 * Tag can be any valid html tag or '' if no wrapper is wanted.
 * This is needed in special cases like inside tables.
 *  */
const RestrictedContentFactory =
  () =>
  ({ isActive, children, tag: Wrapper = 'div' }: RestrictedContentProps) => {
    const content = isActive
      ? obfuscateNode(children, Wrapper === '')
      : children;
    return Wrapper ? (
      <Wrapper className={classNames({ [styles.Wrapper]: isActive })}>
        {content}
      </Wrapper>
    ) : (
      content
    );
  };

export default RestrictedContentFactory;
