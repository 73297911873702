import React, { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import {
  defaultOptions,
  scrollToAnchorElement,
} from '../../../../../common/components/SmoothScroll/helpers';
import {
  filterRanking,
  formatWealthNumber,
  getSearchResults,
  sortRanking,
} from './helpers';
import authStateSelector from '../../../../../shared/selectors/authStateSelector';
import locationStateSelector from '../../../../../shared/selectors/locationStateSelector';
import pianoStateSelector from '../../../../../shared/selectors/pianoStateSelector';
import withHelmet from '../../../../shared/decorators/withHelmet';
import Picture from '../../../../../common/components/Picture';
import Breadcrumbs from '../../components/Breadcrumbs';
import Helmet from '../../components/Helmet';
import Icon from '../../components/Icon';
import Paragraphs from '../../components/Paragraphs';
import TeaserGrid from '../../components/TeaserGrid';
import ActionButtons from './components/ActionButtons';
import { Table } from './components/Table';
import { ReactComponent as NewIcon } from '../../components/SVGIcon/assets/new-in-list.svg';
import {
  STYLE_16X9_440,
  STYLE_16X9_560,
  STYLE_16X9_800,
} from '../../../../../shared/constants/images';
import {
  ROBOTS_META_INDEX_FOLLOW_NOODP_NOOPENER_NOARCHIVE,
  ROBOTS_META_NOINDEX_FOLLOW,
  ROOT_SCHEMA_TYPE_NEWS_ARTICLE,
} from '../../../../../shared/constants/structuredData';
import { GRID_LAYOUT_TEASER_RANKING } from '../../components/TeaserGrid/gridConfigs/constants';
import { LANDING_PAGE_TYPE } from '../LandingPage/constants';
import {
  RANKING_TYPE_RICHEST,
  RANKING_TYPE_WHO_IS_WHO,
  RANKING_TYPE_YOUNG_RICHEST,
  RANKING_TYPE_YOUNG_RICHEST_ENTREPRENEUR,
  RANKING_TYPE_YOUNG_RICHEST_INHERITANCE,
  RANKING_TYPE_YOUNG_RICHEST_STARS,
} from '../Person/constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import section from '../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
// @ts-ignore
import { LegendElements, TableHeaders } from './components/Table/typings';
import { RankingProps } from './typings';

const typesWithoutRankingPosition = [
  RANKING_TYPE_WHO_IS_WHO,
  RANKING_TYPE_YOUNG_RICHEST,
  RANKING_TYPE_YOUNG_RICHEST_INHERITANCE,
  RANKING_TYPE_YOUNG_RICHEST_STARS,
  RANKING_TYPE_YOUNG_RICHEST_ENTREPRENEUR,
];

const isRankingPositionVisible = (rankingType) =>
  !rankingType || !typesWithoutRankingPosition.includes(rankingType);

const ensureTeaserInterface = (nodes = [], isRankingPosition) =>
  // ensure that all required fields are present
  nodes
    .filter((item) => item?.node?.person)
    .map((item) => {
      return {
        node: {
          ...item.node.person,
          createDate: item.node?.person?.createDate || '',
          title: item.node?.person?.name || '',
          shortTitle: item.node.rankingValue || '',
          teaserImage: item.node?.person?.teaserImage || null,
          preferredUri: item.node?.person?.preferredUri || '',
          rankingPosition:
            (isRankingPosition && item.node.rankingPosition) || '',
        },
      };
    });

const Ranking = ({ ranking, location }: RankingProps) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  useEffect(() => {
    if (searchParams?.size > 0) {
      scrollToAnchorElement('ranking-table', {
        ...defaultOptions,
        replace: false,
      });
    }
  }, [searchParams]);

  if (!ranking) {
    return null;
  }

  const { query: locationQuery } = location;
  const queryCopy = JSON.parse(JSON.stringify(locationQuery));

  // verify if filterBy exists in queryCopy object keys
  const hasFilterInQueryCopy = Object.keys(queryCopy).some((item) =>
    item.startsWith('filterBy'),
  );

  const correctRobotsMetaTags =
    ((queryCopy.sortBy || hasFilterInQueryCopy) &&
      ROBOTS_META_NOINDEX_FOLLOW) ||
    ROBOTS_META_INDEX_FOLLOW_NOODP_NOOPENER_NOARCHIVE;

  let rankingItems = ranking?.rankings?.edges || [];

  const setActions = (
    param: string,
    type = 'filterByGender',
    direction = 'asc',
  ) => {
    // encodeURI to prevent non matching filters with whitespace or special chars
    const encodedParam = encodeURI(param);
    if (type === 'sortBy') {
      if (encodedParam === 'rang' && !queryCopy.direction) {
        direction = 'desc';
      }
      setSearchParams(
        {
          ...queryCopy,
          ['sortBy']: encodedParam,
          ['direction']: direction,
        },
        {
          state: { preventScroll: true },
        },
      );
    } else {
      setSearchParams(
        { ...queryCopy, [type]: encodedParam },
        {
          state: { preventScroll: true },
        },
      );
    }
  };

  const industryList = [];
  rankingItems.forEach((item) => {
    const filterItem =
      (item?.node?.rankingIndustry?.includes(`,`) &&
        item?.node?.rankingIndustry?.split(',')) ||
      item?.node?.rankingIndustry;

    if (Array.isArray(filterItem)) {
      const splittedFilterItems = [...filterItem];
      splittedFilterItems.forEach((filter) => {
        industryList.push(filter.trim());
      });
    } else {
      industryList.push(filterItem);
    }
  });

  const stateList = [];
  rankingItems.forEach((item) => {
    const state = item?.node?.rankingState || '';
    return stateList.push(state);
  });

  // remove duplicates from array
  const cleanedIndustryList = [...new Set(industryList)];
  const cleanedStateList = [...new Set(stateList)];

  if (locationQuery?.filterByGender) {
    rankingItems = filterRanking(rankingItems, locationQuery?.filterByGender);
  }

  if (locationQuery?.filterByStatus) {
    rankingItems = filterRanking(rankingItems, locationQuery?.filterByStatus);
  }

  if (locationQuery?.filterByIndustry) {
    rankingItems = filterRanking(rankingItems, locationQuery?.filterByIndustry);
  }

  if (locationQuery?.filterByState) {
    rankingItems = filterRanking(rankingItems, locationQuery?.filterByState);
  }

  if (locationQuery?.sortBy) {
    rankingItems = sortRanking(
      rankingItems,
      locationQuery?.sortBy,
      locationQuery?.direction,
    );
  }

  if (locationQuery?.suche) {
    rankingItems = getSearchResults(rankingItems, locationQuery?.suche);
  }

  const tableHeaders: TableHeaders = [
    { label: 'Rang', type: 'rankingPosition' },
    { label: 'Name', type: 'name' },
    { label: 'Vermögen', type: 'rankingValue' },
    { label: 'Branche', type: 'rankingIndustry' },
    { label: 'Kanton', type: 'rankingState' },
  ];

  const legendElements: LegendElements = [
    {
      icon: <Icon addClass={styles.Positive} type="IconArrowUpRight" />,
      label: 'Aufsteiger',
    },
    {
      icon: <Icon addClass={styles.Negative} type="IconArrowDownRight" />,
      label: 'Absteiger',
    },
    { icon: <Icon type="IconArrowRight" />, label: 'Unverändert' },
    // @ts-ignore
    { icon: (<NewIcon />) as ReactNode, label: 'Neu in Liste' },
    { icon: <Icon type="IconArrowRotateLeft" />, label: 'Rückkehrer' },
  ];

  rankingItems.forEach((item) => {
    if (item?.node?.rankingValue) {
      item.node.rankingValue = formatWealthNumber(item.node.rankingValue);
    }
  });

  const useNewRanking =
    ranking.year >= 2023 && ranking.rankingType === RANKING_TYPE_RICHEST;

  const legacyRankingItems = ensureTeaserInterface(
    rankingItems,
    isRankingPositionVisible(ranking.rankingType),
  );

  const { file } = ranking.teaserImage?.image || {};

  return (
    <div
      className={classNames('ranking', styles.Wrapper, {
        [styles.WithGreyBg]: useNewRanking,
      })}
    >
      <Helmet
        meta={[
          {
            name: 'robots',
            content: correctRobotsMetaTags,
          },
        ]}
      />
      {!useNewRanking && ranking.title && (
        <div className={styles.Header}>
          <div className={section.Container}>
            {ranking.activeMenuTrail && (
              <Breadcrumbs
                pageUrl={ranking.preferredUri}
                items={ranking.activeMenuTrail}
              />
            )}
          </div>
          <div className={section.Container}>
            <h1>
              <div className={styles.Title}>{ranking.title}</div>
              {ranking.lead && (
                <span
                  className={styles.ShortTitleLegacy}
                  dangerouslySetInnerHTML={{ __html: ranking.lead }}
                />
              )}
            </h1>
          </div>
        </div>
      )}
      {useNewRanking && (
        <div className={styles.HeroWrapper}>
          <div className={grid.Container}>
            <div className={grid.Row}>
              <div className={classNames(grid.ColXs24, grid.ColMd12)}>
                <div className={styles.HeroInnerWrapper}>
                  <p className={styles.ShortTitle}>Bilanz</p>
                  <p className={styles.HeroTitle}>{ranking.title}</p>
                  {ranking.lead && (
                    <span
                      className={styles.HeroLead}
                      dangerouslySetInnerHTML={{ __html: ranking.lead }}
                    />
                  )}
                </div>
              </div>
              {file?.relativeOriginPath && (
                <div
                  className={classNames(
                    grid.ColXs24,
                    grid.ColMd12,
                    styles.HeroImageWrapper,
                  )}
                >
                  <Picture
                    relativeOrigin={file.relativeOriginPath}
                    focalPointX={file.focalPointX}
                    focalPointY={file.focalPointY}
                    style_320={STYLE_16X9_440}
                    style_540={STYLE_16X9_560}
                    style_760={STYLE_16X9_800}
                    alt={file.alt || ''}
                    className={styles.HeroImage}
                    downloadPriority="high"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <>
        <div
          id="ranking-table"
          className={classNames('paywall-ranking-list', {
            [styles.TeaserWrapperLegacy]: !useNewRanking,
          })}
        >
          {useNewRanking ? (
            <div
              className={classNames(
                grid.ContainerPullOut,
                styles.RankingsWrapper,
              )}
            >
              <div className={grid.Row}>
                <div className={grid.ColXs24}>
                  <ActionButtons
                    query={queryCopy}
                    industryList={cleanedIndustryList}
                    stateList={cleanedStateList}
                    location={location}
                    setActions={setActions}
                  />
                  {(rankingItems?.length > 0 && (
                    <Table
                      legendElements={legendElements}
                      headers={tableHeaders}
                      rows={rankingItems}
                      setActions={setActions}
                    />
                  )) || (
                    <div
                      className={classNames(
                        grid.Container,
                        styles.EmptyResultsWrapper,
                      )}
                    >
                      <>Keine Resultate</>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <TeaserGrid
              layout={GRID_LAYOUT_TEASER_RANKING}
              items={legacyRankingItems}
            />
          )}
        </div>
        <div className={styles.ParagraphsContainer}>
          <Paragraphs pageBody={ranking.body} origin={LANDING_PAGE_TYPE} />
        </div>
      </>
    </div>
  );
};
const getRootSchemaRestricted = ({
  ranking,
  hasSubscriptions,
  isCrawler,
}: RankingProps & { hasSubscriptions: boolean; isCrawler: boolean }) => {
  const isRestrictedRanking =
    ranking.year >= 2023 && ranking.rankingType === RANKING_TYPE_RICHEST;

  const shouldHideContent = !hasSubscriptions && isRestrictedRanking;

  const isRestricted = (index: number) => {
    if ((shouldHideContent || isCrawler) && index > 2) {
      return true;
    }
    return false;
  };

  const jsonLd = {
    isAccessibleForFree: !isRestrictedRanking,
    hasPart: [],
    articleBody: '',
  };

  const rankingItems = ranking.rankings?.edges || [];
  let content = '';
  rankingItems.forEach((item) => {
    if (isRestricted(item.node.rankingPosition)) {
      content += `Name: ${item?.node?.person?.name}\n`;
      content += `Branche: ${item?.node?.rankingIndustry}\n`;
      content += `${
        item?.node?.person?.body?.replace?.(/<[^>]*>?/gm, '') || ''
      }\n`;
    }
  });
  jsonLd.articleBody = content;

  if (isRestrictedRanking) {
    for (let index = 1; index < rankingItems.length; index++) {
      if (index > 3) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: false,
          cssSelector: `.restricted-section-${index}`,
        });
      } else {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: true,
          cssSelector: `.section-${index}`,
        });
      }
    }
  }

  return jsonLd;
};

const mapStateToProps = (state: ReduxState) => ({
  hasSubscriptions:
    authStateSelector(state).hasSubscriptions ||
    pianoStateSelector(state).isAccessGranted,
  isCrawler: locationStateSelector(state)?.isCrawler || false,
});

export default compose(
  connect(mapStateToProps),
  withHelmet({
    getNode: (mapProps) => mapProps.ranking,
    getNodesCount: (mapProps) => mapProps?.ranking?.rankings?.count || 0,
    rootSchemaType: ROOT_SCHEMA_TYPE_NEWS_ARTICLE,
    getRootSchemaRestricted,
  }),
)(Ranking);
