import React from 'react';
import classNames from 'classnames';
import styles from './styles.legacy.css';
import { SkeletonThemeProps } from '../../../../../../../../../common/components/ContentBox/typings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [styles.GreyC];

type SkletonProps = {
  theme?: SkeletonThemeProps;
};

const LoadingSkeleton = ({ theme }: SkletonProps) => {
  return (
    <div
      className={classNames(styles.SkeletonWrapper, {
        [styles[theme]]: !!theme,
      })}
    >
      <div
        className={classNames(styles.SkeletonLeft, {
          [styles[theme]]: !!theme,
        })}
      ></div>
      <div
        className={classNames(styles.SkeletonRight, {
          [styles[theme]]: !!theme,
        })}
      ></div>
      <div
        className={classNames(styles.SkeletonContent, {
          [styles[theme]]: !!theme,
        })}
      ></div>
      <div
        className={classNames(styles.SkeletonContent, {
          [styles[theme]]: !!theme,
        })}
      ></div>
      <div
        className={classNames(styles.SkeletonContent, {
          [styles[theme]]: !!theme,
        })}
      ></div>
    </div>
  );
};

const Skeleton = ({ ...props }: SkletonProps) => {
  return (
    <>
      <LoadingSkeleton {...props} />
      <LoadingSkeleton {...props} />
      <LoadingSkeleton {...props} />
      <LoadingSkeleton {...props} />
      <LoadingSkeleton {...props} />
    </>
  );
};

export default Skeleton;
