import React from 'react';
import VideoParagraphFactory from '../../../../../../../common/components/Paragraphs/components/VideoParagraph/factory';
import VideoPlayer from '../../../VideoPlayer';
import ImageCaption from '../ImageCaption';
import { VIDEO_PAGE } from '../../../../screens/Video/constants';
import styles from './styles.legacy.css';

const getImageCaptionByProps = ({
  caption,
  credit,
  origin,
  suppressSource,
}) => {
  return (
    <div className={styles.ImageCaptionWrapper}>
      <ImageCaption
        caption={origin !== VIDEO_PAGE ? caption : null}
        credit={credit}
        origin={origin}
        suppressSource={suppressSource}
      />
    </div>
  );
};

export default VideoParagraphFactory({
  styles: {
    Wrapper: styles.Wrapper,
    VideoCredit: styles.VideoCredit,
  },
  Video: VideoPlayer,
  ImageCaption: getImageCaptionByProps,
});
