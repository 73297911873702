import {
  PUBLICATION_HZ,
  PUBLICATION_HZB,
  PUBLICATION_HZB_SEO_TITLE,
  PUBLICATION_HZ_SEO_TITLE,
  PUBLICATION_SWISS_INSURANCE,
  PUBLICATION_SWISS_INSURANCE_SEO_TITLE,
} from '../../../../../shared/constants/publications';

export const PUBLISHER = {
  [PUBLICATION_HZ]: PUBLICATION_HZ_SEO_TITLE,
  [PUBLICATION_SWISS_INSURANCE]: PUBLICATION_SWISS_INSURANCE_SEO_TITLE,
  [PUBLICATION_HZB]: PUBLICATION_HZB_SEO_TITLE,
};

export const PARAGRAPHS_FOR_FREE = 2;
