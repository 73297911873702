import React, { ReactElement, memo } from 'react';
import Header from '../Header';
import { TRACKING_CLASS_SITE_HEADER } from '../../../../../shared/constants/tracking';
import { HeaderAreaComponent, HeaderAreaProps } from './typings';

type HeaderAreaPropsInner = HeaderAreaProps & { headerState?: HeaderState };

const HeaderArea: HeaderAreaComponent = (
  props: HeaderAreaPropsInner,
): ReactElement => {
  const { isStickyEnabled = true, publication, subtypeValue } = props;
  return (
    <div className={TRACKING_CLASS_SITE_HEADER}>
      <Header
        hasStickiness={isStickyEnabled}
        publication={publication}
        subtypeValue={subtypeValue}
      />
    </div>
  );
};

export default memo<HeaderAreaPropsInner>(HeaderArea);
