import React from 'react';
import classNames from 'classnames';
import Dropdown from '../../../../../../components/Dropdown';
import DropdownItem from '../../../../../../components/Dropdown/components/DropdownItem';
import Icon from '../../../../../../components/Icon';
import styles from './styles.legacy.css';
import { SortingDropdownProps } from './typings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  styles.Disabled,
  styles.DropdownListItem,
  styles.DescriptionFavorit,
  styles.Icons,
  styles.DeleteItem,
];

const SortingDropdown = ({ setActions, query }: SortingDropdownProps) => {
  return (
    <>
      <Dropdown
        key={`mobile-sorting-dropdown${!query.sortBy ? '-active' : ''}`}
        align="left"
        iconTypeLeft="IconTableVertical"
        iconTypeRight="IconChevronDown"
        iconTypeRightActive="IconChevronUp"
        variant="secondary"
        label="Branche"
        loading={false}
        mobileFullWidth
      >
        <DropdownItem
          label="Rang absteigend"
          initActive={
            !query.sortBy ||
            (query.sortBy === 'rang' && query.direction === 'desc')
          }
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`sorting-dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('rang', 'sortBy', 'desc');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('rang', 'sortBy', 'desc');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Rang aufsteigend"
          initActive={query.sortBy === 'rang' && query.direction === 'asc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('rang', 'sortBy');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('rang', 'sortBy');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Name A-Z"
          initActive={query.sortBy === 'name' && query.direction === 'asc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('name', 'sortBy');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('name', 'sortBy');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Name Z-A"
          initActive={query.sortBy === 'name' && query.direction === 'desc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('name', 'sortBy', 'desc');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('name', 'sortBy', 'desc');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Vermögen absteigend"
          initActive={query.sortBy === 'vermögen' && query.direction === 'desc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('vermögen', 'sortBy', 'desc');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('vermögen', 'sortBy', 'desc');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Vermögen aufsteigend"
          initActive={query.sortBy === 'vermögen' && query.direction === 'asc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('vermögen', 'sortBy');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('vermögen', 'sortBy');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Branche A-Z"
          initActive={query.sortBy === 'branche' && query.direction === 'asc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('branche', 'sortBy');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('branche', 'sortBy');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Branche Z-A"
          initActive={query.sortBy === 'branche' && query.direction === 'desc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('branche', 'sortBy', 'desc');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('branche', 'sortBy', 'desc');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Kanton A-Z"
          initActive={query.sortBy === 'kanton' && query.direction === 'asc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('kanton', 'sortBy');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('kanton', 'sortBy');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        <DropdownItem
          label="Kanton Z-A"
          initActive={query.sortBy === 'kanton' && query.direction === 'desc'}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                setActions('kanton', 'sortBy', 'desc');
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                setActions('kanton', 'sortBy', 'desc');
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
      </Dropdown>
    </>
  );
};

export default SortingDropdown;
