import React from 'react';

const SocialInstagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    aria-hidden="true"
  >
    <g>
      <path d="M16 5.62c3.134 0 3.5.012 4.743.069a6.473 6.473 0 012.224.415l-.045-.015a3.912 3.912 0 012.218 2.205l.009.026a6.36 6.36 0 01.4 2.177v.003c.057 1.237.069 1.608.069 4.742s-.012 3.5-.069 4.743a6.473 6.473 0 01-.415 2.224l.015-.045a3.91 3.91 0 01-2.201 2.219l-.026.009a6.406 6.406 0 01-2.175.4h-.004c-1.238.056-1.609.068-4.743.068s-3.505-.012-4.743-.068a6.52 6.52 0 01-2.224-.415l.045.015A3.91 3.91 0 016.86 22.19l-.009-.026a6.36 6.36 0 01-.4-2.176v-.003c-.057-1.238-.069-1.609-.069-4.743s.012-3.505.069-4.742a6.474 6.474 0 01.415-2.225l-.015.045a3.914 3.914 0 012.201-2.218l.026-.009a6.36 6.36 0 012.176-.4h.003C12.5 5.632 12.866 5.62 16 5.62zm0-2.114c-3.187 0-3.587.013-4.839.07a8.614 8.614 0 00-2.908.566l.059-.02a6.03 6.03 0 00-3.418 3.391l-.014.041a8.416 8.416 0 00-.546 2.837v.009c-.057 1.252-.071 1.652-.071 4.839s.014 3.587.071 4.839a8.583 8.583 0 00.566 2.911l-.02-.059a6.038 6.038 0 003.391 3.419l.041.014c.844.326 1.82.524 2.84.545h.009c1.252.057 1.652.071 4.839.071s3.587-.014 4.839-.071a8.589 8.589 0 002.908-.565l-.059.02a6.038 6.038 0 003.418-3.392l.014-.041a8.41 8.41 0 00.546-2.84v-.009c.057-1.252.071-1.651.071-4.839s-.014-3.587-.071-4.839a8.59 8.59 0 00-.566-2.908l.02.059a6.03 6.03 0 00-3.391-3.418l-.041-.014a8.458 8.458 0 00-2.84-.546h-.009c-1.252-.057-1.652-.07-4.839-.07z" />
      <path d="M16 9.215a6.027 6.027 0 100 12.054 6.027 6.027 0 100-12.054zm0 9.939a3.911 3.911 0 110-7.824 3.911 3.911 0 013.912 3.912v.001a3.911 3.911 0 01-3.911 3.911H16zM23.673 8.977a1.408 1.408 0 11-2.815.001 1.408 1.408 0 012.815-.001z" />
    </g>
  </svg>
);

export default SocialInstagram;
