/**
 * @file   Renders an inline image
 * @date   2016-07-11
 */

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import ImageCaption from '../ImageCaption';
import { STYLE_INLINE_IMAGE_1200 } from '../../../../../../../shared/constants/images';
import { MULTI_COLUMNS_PARAGRAPH } from '../../../../../../../shared/constants/paragraphs';
import {
  TRACKING_CLASS_IMAGE_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../shared/constants/tracking';
import styles from './styles.legacy.css';
import { ImageParagraphProps } from './typings';

export type ImageParagraphPropsInner = ImageParagraphProps;

const ImageParagraphComponent = ({
  imageParagraph,
  plainImage = false,
  origin,
  hideCaption,
  heroMediaParagraph,
}: ImageParagraphPropsInner): ReactElement => {
  if (!imageParagraph || !imageParagraph.image) {
    return null;
  }
  const originalUrl =
    (imageParagraph.image?.showOriginal &&
      imageParagraph.image?.file?.origin) ||
    null;

  let imgComp: ReactElement = (
    <Picture
      url={originalUrl}
      showOriginal={imageParagraph.image?.showOriginal}
      relativeOrigin={imageParagraph.image.file.relativeOriginPath}
      focalPointX={imageParagraph.image.file.focalPointX}
      focalPointY={imageParagraph.image.file.focalPointY}
      alt={imageParagraph.image.file.alt}
      style_320={STYLE_INLINE_IMAGE_1200}
      width={imageParagraph?.image?.file?.width}
      height={imageParagraph?.image?.file?.height}
    />
  );

  if (imageParagraph.link && imageParagraph.link.path) {
    imgComp = <Link {...imageParagraph.link}>{imgComp}</Link>;
  }

  imgComp =
    (imageParagraph?.image?.file?.width < imageParagraph?.image?.file?.height &&
      origin !== MULTI_COLUMNS_PARAGRAPH &&
      !imageParagraph.fullWidth && (
        <div
          className={classNames(
            heroMediaParagraph
              ? styles.PortraitWrapperHeroMedia
              : styles.PortraitWrapper,
          )}
        >
          {imgComp}
        </div>
      )) ||
    imgComp;

  if (!plainImage) {
    return (
      <div
        className={classNames(
          TRACKING_CLASS_PARAGRAPH,
          TRACKING_CLASS_IMAGE_PARAGRAPH,
          styles.ImageWrapper,
        )}
        data-testid="image-paragraph-wrapper"
      >
        {imgComp}
        {!hideCaption && (
          <div className={styles.ImageCaptionWrapper}>
            <ImageCaption
              origin={origin}
              caption={imageParagraph.caption || ''}
              credit={imageParagraph.image?.credit || ''}
              suppressSource={imageParagraph.suppressSource}
            />
          </div>
        )}
      </div>
    );
  }

  return imgComp;
};

export default ImageParagraphComponent;
