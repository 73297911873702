import React from 'react';

const SocialXing = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    aria-hidden={true}
  >
    <g>
      <path d="M14.6 13.9l-2.1-3.5c-.2-.3-.4-.5-.8-.5H8.6c-.2 0-.3.1-.4.2s-.1.3 0 .5l2.1 3.4-3.2 5.5c-.1.2-.1.3 0 .4s.2.2.4.2h3.1c.4 0 .7-.3.8-.6 3.1-5.4 3.2-5.6 3.2-5.6zm10.3-7.8c-.1-.1-.2-.2-.4-.2h-3.1c-.5 0-.7.3-.8.5-6.6 11.1-6.8 11.5-6.8 11.5l4.3 7.6c.1.3.4.5.8.5H22c.2 0 .3-.1.4-.2s.1-.3 0-.5l-4.3-7.5 6.8-11.4c.1 0 .1-.2 0-.3z" />
    </g>
  </svg>
);

export default SocialXing;
