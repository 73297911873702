import React, { ReactElement } from 'react';
import TeaserGrid from '../TeaserGrid';
import { PERSON_PAGE_TYPE } from '../../screens/Person/constants';
import { GRID_LAYOUT_TEAM_MEMBERS } from '../TeaserGrid/gridConfigs/constants';
import styles from './styles.legacy.css';
import { TeamMembersProps } from './typings';

const ensureTeaserInterface = (nodes, title) =>
  // ensure that all required fields are present
  nodes
    .map((item) => ({
      node: {
        ...item.node,
        createDate: item?.node?.person?.createDate || '',
        title: item?.node?.person?.title || '',
        shortTitle: item?.node?.organization?.title || '',
        teaserImage: item?.node?.person?.teaserImage || null,
        preferredUri: item?.node?.organization?.preferredUri || '',
        position: item?.node?.position || '',
      },
    }))
    .filter((item) => item?.node?.person?.title !== title); // Person Profile Site shouldn't refer to itself

const TeamMembers = ({
  persons,
  title,
  origin,
}: TeamMembersProps): ReactElement => {
  if (
    !persons ||
    !persons.edges ||
    ensureTeaserInterface(persons.edges, title).length === 0 ||
    (title &&
      persons.edges.length === 1 && // Person Profile Site shouldn't refer to itself and it should return, if the only person is itself
      persons.edges.filter((item) => item.node.person.title !== title))
  ) {
    return null;
  }

  return (
    <div className={styles.Wrapper}>
      <h3 className={styles.Title}>
        {(origin === PERSON_PAGE_TYPE &&
          persons.edges?.[0]?.node?.organization?.title &&
          `FirmenkollegInnen bei ${persons.edges?.[0]?.node?.organization?.title}`) ||
          'MitarbeiterInnen'}
      </h3>
      <TeaserGrid
        layout={GRID_LAYOUT_TEAM_MEMBERS}
        items={ensureTeaserInterface(persons.edges, title)}
      />
    </div>
  );
};

export default TeamMembers;
