import React from 'react';

const Menu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g>
      <path d="M4.48 23.92h23.04v-2.24H4.48v2.24zm0-8h23.04v-2.24H4.48v2.24zm0-8h23.04V5.68H4.48v2.24z" />
    </g>
  </svg>
);

export default Menu;
