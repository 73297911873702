/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { GET_ONMEDA_LISTING } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ params: { char = 'A', category = 'Finanzlexikon' } }) => {
    return {
      query: GET_ONMEDA_LISTING,
      variables: {
        char: char.toUpperCase(),
        publication: 'HZ',
        category,
      },
    };
  },
};
