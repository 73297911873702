/* istanbul ignore file */

import editorialPicksFactory from '../../../../../common/components/EditorialPicks/factory';
import ContentBox from '../ContentBox';
import Skeleton from './components/Skeleton';
import styles from './styles.legacy.css';
import { apolloConfig } from './apolloConfig';

const EditorialPicks = editorialPicksFactory({
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
  },
  ContentBox,
  apolloConfig,
  Skeleton,
});

export default EditorialPicks;
