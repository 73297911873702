import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import locationStateSelector from '../selectors/locationStateSelector';

const useIsSSR = (): boolean => {
  const isInitialPage = useSelector(
    (state) => locationStateSelector(state).isInitialPage,
  );
  const [isSSR, setIsSSR] = useState<boolean>(isInitialPage);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  return isSSR;
};

export default (!__TESTING__ && useIsSSR) || (() => false);
