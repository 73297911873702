import storageAvailable from '../../../shared/helpers/storage';
import { setPianoAccesGranted } from '../../../shared/actions/piano';
import { Auth0 } from '../Auth0Provider';

// export const handleLoginPromise = async (location: RaschRouterLocation) => {
//   Auth0.login('general', '', location.state?.prevLocation || '/');
// };

export const handleLoginPromise = new Promise((resolve) => {
  global?.GaaMetering?.getLoginPromise().then(() => {
    //eslint-disable-next-line
    console.log('xxx - ARE WE HERE IN THE LOGIN CASE????');
    Auth0.login('google_login');
    // location.href = 'https://welcome.s.onelog.ch&google_login=true';
    //@TODO: Pass params to OneLog
    // TODO: Handle login for the existing user and resolve the userState.
    // Example userState below is for a registered user with no publisher access.
    // const loggedInUserState = {
    //   id: 'user1235',
    //   registrationTimestamp: 1602763054,
    //   granted: false,
    // };

    const userState = (storageAvailable('localStorage') &&
      JSON.parse(localStorage.getItem('gaa:userState'))) || { granted: false };
    resolve(userState);
  });
});

export const handleRegisterUserPromise = new Promise((resolve) => {
  global?.GaaMetering?.getGaaUserPromise().then((gaaUser) => {
    //eslint-disable-next-line
    console.log('xxx - ARE WE HERE IN THE REGISTER CASE????');
    //@TODO: Pass params to OneLog
    // location.href =
    //   'https://welcome.s.onelog.ch/?client=8f1cdf98498a067a2afbfae42ec9a882&interaction=DQ0bb5H78NQnfYWvIVO8O&login_case=general&source=DIR_HOME_PT_LandingPage&lang=de&reason=no_session&brand_theme=&email_hint=&email_force=&connection=&gacid=GA1.1.398942955.1729084163&ext_tracking_consent=true&application_type=web&google_login=true';
    // Auth0.login('google_login');
    //eslint-disable-next-line
    console.log('xxx - gaaUser [handleRegisterUserPromise]: ', gaaUser);
    // TODO: Handle registration for the gaaUser and resolve the userState.
    // Example userState below is for a registered user with publisher metering access.
    // const registeredUserState = {
    //   id: 'user1235',
    //   registrationTimestamp: 1602763054,
    //   granted: true,
    //   grantReason: 'METERING',
    // };
    const userState = (storageAvailable('localStorage') &&
      JSON.parse(localStorage.getItem('gaa:userState'))) || { granted: false };
    resolve(userState);
  });
});

// export const handleRegisterUserPromise = async (
//   location: RaschRouterLocation,
// ) => {
//   Auth0.login('general', '', location.state?.prevLocation || '/');
// };

export const handleUnlockArticle = async (dispatch) => {
  //eslint-disable-next-line
  console.log('xxx - UNLOCK ARTICLE!!!');
  dispatch(setPianoAccesGranted(true));
  // setPianoAccesGranted(true);
};

export const handleShowPaywall = async (dispatch) => {
  //eslint-disable-next-line
  console.log('xxx - LOCK ARTICLE!!!');
  dispatch(setPianoAccesGranted(false));
};

// export const publisherEntitlementPromise = async () => {
//   return window.__GAA_USERSTATE__;
// };

export const publisherEntitlementPromise = new Promise((resolve) => {
  const userState = (storageAvailable('localStorage') &&
    JSON.parse(localStorage.getItem('gaa:userState'))) || { granted: false };
  resolve(userState);
});

// export const handleSwGEntitlement = () => {
//   // eslint-disable-next-line
//   console.log('xxx - handleSwGEntitlement CALLBACK');
// };
