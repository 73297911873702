import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import Icon from '../../../../components/Icon';
import styles from './styles.legacy.css';
import { SearchInputProps } from './typings';

const SearchInput = ({ setActions, addClass = '' }: SearchInputProps) => {
  const [hasInputFocus, setHasInputFocus] = useState(false);
  const location = useRaschRouterLocation();
  const inputRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    if (inputRef?.current)
      if (location?.query?.suche) {
        inputRef.current.value = decodeURI(location?.query?.suche);
      } else {
        inputRef.current.value = '';
      }
  }, [location?.query?.suche]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams({});
  const queryCopy = JSON.parse(JSON.stringify(location?.query));

  return (
    <div className={styles.InputWrapper}>
      <div
        className={classNames(styles.InputWithIconWrapper, {
          [styles.InputStylesInputFocused || '']:
            hasInputFocus || !!location?.query?.suche,
        })}
      >
        <Icon
          type={'IconMagnifyingGlass'}
          addClass={classNames(styles.InputIcon, {
            [addClass]: !!addClass,
          })}
          onClick={() => inputRef.current.focus()}
        />
        <input
          key={location?.query?.suche}
          // eslint-disable-next-line
          autoFocus={hasInputFocus}
          name="ranking-search-input"
          type="search"
          aria-label="ranking-search-input"
          placeholder="Suche"
          className={classNames(styles.Input, {
            [styles.InputStylesInputFocused || '']:
              hasInputFocus || !!location?.query?.suche,
          })}
          ref={inputRef}
          onChange={(e) => {
            if (debounceTimeoutRef?.current) {
              clearTimeout(debounceTimeoutRef.current);
            }
            if (e.target.value.length === 0) {
              delete queryCopy.suche;
              setSearchParams(
                { ...queryCopy },
                { state: { preventScroll: true } },
              );
              setHasInputFocus(true);
            } else {
              debounceTimeoutRef.current = setTimeout(() => {
                setActions(e.target.value, 'suche');
              }, 300);
            }
          }}
          onFocus={() => setHasInputFocus(true)}
          onBlur={() => setHasInputFocus(false)}
        />
      </div>
    </div>
  );
};

export default SearchInput;
