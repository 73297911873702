/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  PUBLICATION_GROUP_BIL,
  PUBLICATION_GROUP_HZ,
} from '../../../../../shared/constants/publications';
import { ROUTE_SUBSCRIPTIONS, ROUTE_SUBSCRIPTIONS_BIL } from '../../constants';
import { GET_SUBSCRIPTIONS } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location }) => {
    const subscriptionPub =
      location.pathname === ROUTE_SUBSCRIPTIONS_BIL
        ? PUBLICATION_GROUP_BIL
        : PUBLICATION_GROUP_HZ;
    return {
      query: GET_SUBSCRIPTIONS,
      variables: {
        path: ROUTE_SUBSCRIPTIONS,
        publication: subscriptionPub,
      },
    };
  },
};
