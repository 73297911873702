import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import storageAvailable from '../../../shared/helpers/storage';
import {
  handleLoginPromise,
  handleRegisterUserPromise,
  handleShowPaywall,
  handleUnlockArticle,
  publisherEntitlementPromise,
} from './helpers';
import authStateSelector from '../../../shared/selectors/authStateSelector';
// import pianoStateSelector from '../../../shared/selectors/pianoStateSelector';
import useIsSSR from '../../../shared/hooks/useIsSSR';
import useRaschRouterLocation from '../../../shared/hooks/useRaschRouterLocation';
import { Auth0 } from '../Auth0Provider';
// import { ARTICLE_CONTENT_TYPE } from '../../../shared/constants/content';
import { allowedReferrers } from './constants';
import { GoogleNewsShowcaseFactoryProps } from './typings';

const initGaa = debounce(
  async (
    // disableGoogleIntervention: Boolean,
    isAlreadyInitializedRef: React.MutableRefObject<boolean>,
    // location,
  ) => {
    // const userState = window.__GAA_USERSTATE__;
    const userState =
      (storageAvailable('localStorage') &&
        JSON.parse(localStorage.getItem('gaa:userState'))) ||
      null;
    // const userState = JSON.parse(localStorage.getItem('gaa:userState'));

    if (!isAlreadyInitializedRef.current && !!userState) {
      // eslint-disable-next-line
      console.log('xxx - INITIALIZE GOOGLE API with userState:', userState);
      isAlreadyInitializedRef.current = true;

      // @TODO: Currently we haven't activated our account by Google.
      // As soon as we have it, we can try to implement the call to the google API and
      // test the full workflow.

      // authorizationUrl: 'https://accounts.google.com/o/oauth2/v2/auth/',

      const authorizationUrl = await Promise.resolve(
        Auth0.getLoginUrl('google_login', null, null, null),
      );
      // `https://welcome.s.onelog.ch/?client=8f1cdf98498a067a2afbfae42ec9a882&interaction=Eg2y570h8mlgQW5xbShiR&login_case=general&source=DIR_Panorama_PT_Article_PID_520655&lang=de&reason=no_session&brand_theme=&email_hint=&email_force=&connection=&google_login=true&ext_tracking_consent=true&application_type=web&google_login=true`;
      // encodeURIComponent(location.href);

      //eslint-disable-next-line
      console.log('xxx - authorizationUrl: ', authorizationUrl);

      //@ts-ignore
      global?.GaaMetering?.init({
        authorizationUrl: authorizationUrl,
        // googleApiClientId:
        //   '758964806654-ds4bih0ujsic9807bgouml93n33n2b9i.apps.googleusercontent.com',
        userState: userState,
        allowedReferrers: allowedReferrers,
        handleLoginPromise: handleLoginPromise,
        registerUserPromise: handleRegisterUserPromise,
        publisherEntitlementPromise: publisherEntitlementPromise,
        unlockArticle: handleUnlockArticle,
        showPaywall: handleShowPaywall,
        shouldInitializeSwG: false,
      });
    }
  },
  1000,
);

const googleNewsShowcaseProviderFactory = ({
  Helmet,
}: GoogleNewsShowcaseFactoryProps) => {
  const GoogleNewsShowcaseProvider = () => {
    const location = useRaschRouterLocation();
    const internalUserId = Auth0.getInternalUserId();
    const isAlreadyInitializedRef = useRef(false);
    const isSSR = useIsSSR();

    const isAuthenticated = useSelector<ReduxState, boolean>(
      (state) => authStateSelector(state).isAuthenticated,
    );

    const hasSubscriptions = useSelector<ReduxState, boolean>(
      ({ auth }) => auth.hasSubscriptions || false,
    );

    const registrationTimestamp = useSelector<ReduxState, number | null>(
      ({ auth }) => auth.registrationTimestamp || 1729061020 || null,
    );

    const subscriptionTimestamp = useSelector<ReduxState, number | null>(
      ({ auth }) => auth.subscriptionTimestamp || null,
    );

    // const contentType = useSelector(
    //   (state) => pianoStateSelector(state).pageMetadata.contentType,
    // );

    // Check if User comes directly from Google Show Case
    // TODO: further check if it's a valid Google News Showcase URL with the gaa params
    // description here: https://developers.google.com/news/subscribe/extended-access/reference/google-article-access-parameters#validating-the-gaa_sig-parameter
    const isFromGoogleNewsShowCase =
      location?.query?.utm_source === 'newsshowcase';

    // const disableGoogleIntervention =
    //   !isFromGoogleNewsShowCase || contentType !== ARTICLE_CONTENT_TYPE;

    useEffect(() => {
      let gaaUserState = null;
      if (isAuthenticated && !hasSubscriptions) {
        // logged in user with no paid subscription
        // window.__GAA_USERSTATE__
        gaaUserState = {
          id: internalUserId,
          registrationTimestamp, //@TODO: get the regidtertedTimestamp from Piano
          granted: false,
        };
      }

      // logged in user with valid paid subscription
      if (isAuthenticated && hasSubscriptions) {
        // window.__GAA_USERSTATE__
        gaaUserState = {
          id: internalUserId,
          registrationTimestamp, //@TODO: get the regidtertedTimestamp from Piano
          subscriptionTimestamp, //@TODO: get the subscriptionTimestamp from Piano
          granted: true,
          grantReason: 'SUBSCRIBER',
        };
      }

      if (!isAuthenticated) {
        // anonymus User – Not logged in, no Abo Subscription
        // window.__GAA_USERSTATE__
        gaaUserState = {
          granted: false,
        };
      }
      // console.log('xxx - gaaUserState: ', gaaUserState);
      const hasLocalStorage = storageAvailable('localStorage');
      if (hasLocalStorage) {
        localStorage.setItem('gaa:userState', JSON.stringify(gaaUserState));
      }
    }, [
      isFromGoogleNewsShowCase,
      isAuthenticated,
      internalUserId,
      hasSubscriptions,
      subscriptionTimestamp,
      registrationTimestamp,
    ]);

    if (!isSSR && !isAlreadyInitializedRef.current) {
      initGaa(isAlreadyInitializedRef);
    }

    // JUST FOR TESTING THE CALLBACKS - * START *
    // @TODO: These EventListeners can be removed after Gaa initialization is implemented completely
    //   const dispatch = useDispatch();

    //   window.addEventListener('unlockArticle', () => {
    //     handleUnlockArticle(dispatch);
    //   });

    //   window.addEventListener('showPaywall', () => {
    //     handleShowPaywall(dispatch);
    //   });

    //   window.addEventListener('handleLoginPromise', async () => {
    //     await handleLoginPromise(location);
    //   });

    //   window.addEventListener('registerUserPromise', async () => {
    //     await handleRegisterUserPromise(location);
    //   });

    return (
      <Helmet
        script={[
          {
            src: 'https://accounts.google.com/gsi/client',
            async: true,
            defer: true,
          },
          {
            src: 'https://news.google.com/swg/js/v1/swg-gaa.js',
            async: true,
          },
          {
            src: 'https://news.google.com/swg/js/v1/swg.js',
            async: true,
            'subscriptions-control': 'manual',
          },
        ]}
      />
    );
  };
  return GoogleNewsShowcaseProvider;
};

export default googleNewsShowcaseProviderFactory;
