import React from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { truncateByChars } from '../../../../../../../../../shared/helpers/utils';
import Dropdown from '../../../../../../components/Dropdown';
import DropdownItem from '../../../../../../components/Dropdown/components/DropdownItem';
import Icon from '../../../../../../components/Icon';
import styles from './styles.legacy.css';
import { FilterDropdownProps } from './typings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [styles.Disabled];

const FilterDropdown = ({
  setActions,
  industryList,
  stateList,
  query,
}: FilterDropdownProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams({});

  if (!industryList || !stateList) {
    return null;
  }

  return (
    <>
      <Dropdown
        key={`industry-filter-dropdown${
          !query.filterByIndustry ? '-active' : ''
        }`}
        align="left"
        iconTypeLeft="IconTableVertical"
        iconTypeRight="IconChevronDown"
        iconTypeRightActive="IconChevronUp"
        variant="secondary"
        label="Branche"
        loading={false}
        mobileFullWidth
      >
        <DropdownItem
          label="Alle Branchen"
          initActive={!query.filterByIndustry}
        >
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                if (query.filterByIndustry) {
                  delete query['filterByIndustry'];
                  setSearchParams(
                    { ...query },
                    {
                      state: { preventScroll: true },
                    },
                  );
                }
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                if (query.filterByIndustry) {
                  delete query['filterByIndustry'];
                  setSearchParams(
                    { ...query },
                    {
                      state: { preventScroll: true },
                    },
                  );
                }
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        {industryList?.map((industry: string, index) => {
          const encodedIndustry = encodeURI(industry);
          return (
            <DropdownItem
              key={`industry-dropdown-item-${industry}-${index}`}
              label={truncateByChars(industry, 20, '...')}
              initActive={query?.filterByIndustry === encodedIndustry}
            >
              {({ handleOptionClick, itemId, isActive }) => (
                <div
                  key={`dropdown-option-${itemId}`}
                  className={classNames(styles.DropdownViewLink, {
                    [styles.Active]: isActive,
                  })}
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    handleOptionClick(itemId);
                    setActions(industry, 'filterByIndustry');
                  }}
                  onKeyDown={() => {
                    handleOptionClick(itemId);
                    setActions(industry, 'filterByIndustry');
                  }}
                >
                  {industry}
                  {isActive ? (
                    <Icon
                      type="IconCheck"
                      addClass={styles.CheckmarkIcon}
                    ></Icon>
                  ) : null}
                </div>
              )}
            </DropdownItem>
          );
        })}
      </Dropdown>
      <Dropdown
        key={`state-filter-dropdown${!query.filterByState ? '-active' : ''}`}
        align="left"
        iconTypeLeft="IconTableVertical"
        iconTypeRight="IconChevronDown"
        iconTypeRightActive="IconChevronUp"
        variant="secondary"
        label="Kanton"
        loading={false}
        mobileFullWidth
      >
        <DropdownItem label="Alle Kantone" initActive>
          {({ handleOptionClick, itemId, isActive, label }) => (
            <div
              key={`dropdown-option-${itemId}`}
              className={classNames(styles.DropdownViewLink, {
                [styles.Active]: isActive,
              })}
              role="link"
              tabIndex={0}
              onClick={() => {
                handleOptionClick(itemId);
                if (query.filterByState) {
                  delete query['filterByState'];
                  setSearchParams(
                    { ...query },
                    {
                      state: { preventScroll: true },
                    },
                  );
                }
              }}
              onKeyDown={() => {
                handleOptionClick(itemId);
                if (query.filterByState) {
                  delete query['filterByState'];
                  setSearchParams(
                    { ...query },
                    {
                      state: { preventScroll: true },
                    },
                  );
                }
              }}
            >
              {label}
              {isActive ? (
                <Icon type="IconCheck" addClass={styles.CheckmarkIcon}></Icon>
              ) : null}
            </div>
          )}
        </DropdownItem>
        {stateList?.map((state: any, index) => {
          const encodedState = encodeURI(state);
          return (
            <DropdownItem
              key={`state-dropdown-item-${state}-${index}`}
              label={truncateByChars(state, 20, '...')}
              initActive={query?.filterByState === encodedState}
            >
              {({ handleOptionClick, itemId, isActive }) => (
                <div
                  key={`dropdown-option-${itemId}`}
                  className={classNames(styles.DropdownViewLink, {
                    [styles.Active]: isActive,
                  })}
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    handleOptionClick(itemId);
                    setActions(state, 'filterByState');
                  }}
                  onKeyDown={() => {
                    handleOptionClick(itemId);
                    setActions(state, 'filterByState');
                  }}
                >
                  {state}
                  {isActive ? (
                    <Icon
                      type="IconCheck"
                      addClass={styles.CheckmarkIcon}
                    ></Icon>
                  ) : null}
                </div>
              )}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </>
  );
};

export default FilterDropdown;
