import React from 'react';

const Search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g>
      <path d="M4.48 12.4c0 5.472 4.448 9.92 9.92 9.92 2.528 0 4.832-.96 6.592-2.528l7.872 7.04 1.472-1.664-7.872-7.008c1.152-1.632 1.856-3.616 1.856-5.76 0-5.472-4.448-9.92-9.92-9.92S4.48 6.928 4.48 12.4zm2.24 0c0-4.256 3.424-7.68 7.68-7.68s7.68 3.424 7.68 7.68-3.424 7.68-7.68 7.68-7.68-3.424-7.68-7.68z" />
    </g>
  </svg>
);

export default Search;
