import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import Icon from '../../../../components/Icon';
import TableRow from '../TableRow';
import { getIsReferrerWhitelisted } from '../../../ArticlePage';
import styles from './styles.legacy.css';
import { TableProps } from './typings';

export const Table = ({
  headers,
  rows,
  legendElements,
  setActions,
}: TableProps) => {
  const location = useRaschRouterLocation();
  const hasSubscriptions = useSelector<ReduxState, boolean>(
    ({ auth }) => auth.hasSubscriptions || false,
  );
  const isAccessGranted = useSelector<ReduxState, boolean>(
    ({ piano }) => piano.isAccessGranted || false,
  );
  const isCrawler = useSelector<ReduxState, boolean>(
    ({ route }) => route.isCrawler || false,
  );

  const sortByField = decodeURI(location?.query?.sortBy) || 'rang';
  let sortDirection = location?.query?.direction || 'desc';

  const [isLoading, startTransition] = useTransition();
  const firstRow = rows[0]?.node;
  const [activeRowIds, setActiveRowIds] = useState([
    firstRow.rankingPosition === 1 ? firstRow.person.id : null,
  ]);
  const [isLegendVisible, setShowLegend] = useState(false);
  const legendRef = useRef<HTMLParagraphElement>();

  const shouldHideContent = !hasSubscriptions && !getIsReferrerWhitelisted();

  const isRestricted = (index: number) => {
    if (shouldHideContent && index > 3 && !isCrawler && !isAccessGranted) {
      return true;
    }
    return false;
  };

  const toggleRow = (id: string, rankingPosition: number) => {
    if (isRestricted(rankingPosition)) {
      const isProd = __DOT_ENV__ === 'master' || __DOT_ENV__ === 'production';
      const pianoConfig = {
        sandbox: {
          templateId: 'OTUWY6L1YOMP',
          offerId: 'OF932KV6QWEK',
          templateVariantId: '',
        },
        production: {
          templateId: 'OTQ3QWRSQ5SZ',
          offerId: 'OFS05TAVPF79',
          templateVariantId: 'OTV9U6C59KCS9',
        },
      };

      //@TODO: add correct Id's for production
      if (global?.tp?.offer) {
        global.tp.offer.show({
          templateId:
            pianoConfig[(isProd && 'production') || 'sandbox'].templateId,
          offerId: pianoConfig[(isProd && 'production') || 'sandbox'].offerId,
          templateVariantId:
            pianoConfig[(isProd && 'production') || 'sandbox']
              .templateVariantId,
          displayMode: 'modal',
          showCloseButton: true,
        });
      }
      return;
    }
    startTransition(() =>
      activeRowIds.includes(id)
        ? setActiveRowIds(activeRowIds.filter((activeId) => activeId !== id))
        : setActiveRowIds([...activeRowIds, id]),
    );
  };

  const handleChangeSorting = (fieldValue: string) => {
    if (sortByField === fieldValue) {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      sortDirection = 'asc';
    }

    setActions(fieldValue, 'sortBy', sortDirection);
  };

  const getKey = (node: Rankings) =>
    `${node.ranking.id}${node.person?.id || Math.random() * 1000}`;

  const handleOutsideClick = useCallback(({ target }) => {
    if (!legendRef.current.contains(target)) {
      setShowLegend(false);
    }
  }, []);

  useEffect(() => {
    if (isLegendVisible) {
      global.addEventListener('click', handleOutsideClick);
    }

    return () => {
      global.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick, isLegendVisible]);

  return (
    <>
      <div className={styles.LegendWrapper}>
        <div
          ref={legendRef}
          onClick={() => setShowLegend(!isLegendVisible)}
          onKeyDown={() => setShowLegend(isLegendVisible)}
          role="button"
          tabIndex={0}
          className={classNames(styles.Legend, {
            [styles.IsOpen]: isLegendVisible,
          })}
        >
          <p className={styles.LegendLabel}>Legende anzeigen</p>
          <Icon addClass={styles.LegendIcon} type="IconCircleInfo" />
          <div
            className={classNames(styles.TooltipWrapper, {
              [styles.IsOpen]: isLegendVisible,
            })}
          >
            <table className={styles.TooltipTable}>
              {legendElements.map((el) => (
                <tr key={el.label} className={styles.LegendItem}>
                  <td className={styles.LegendItemIcon}>
                    {el.icon as ReactNode}
                  </td>
                  <td>{el.label}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <table className={styles.RankingTable}>
        <thead className={styles.TableHead}>
          <tr>
            {headers.map((header) => (
              <th
                className={styles.TableHeadCell}
                onClick={() => handleChangeSorting(header.label.toLowerCase())}
                key={`${header.type}${header.label}`}
              >
                <p className={styles.TableHeadCellLabel}>
                  {header.label}
                  {sortByField === header.label.toLowerCase() && (
                    <Icon
                      type={`${
                        (sortDirection === 'desc' && 'IconArrowDownList') ||
                        'IconArrowUpList'
                      }`}
                      addClass={classNames(styles.SortByIcon, {})}
                    />
                  )}
                </p>
              </th>
            ))}
            <th className={styles.TableHeadCell}>&nbsp;</th>
          </tr>
        </thead>

        <tbody className={styles.TableBody}>
          {JSON.parse(JSON.stringify(rows)).map(
            (row: RankingsEdge, index: number) => (
              <TableRow
                key={getKey(row.node)}
                row={row}
                toggleRow={toggleRow}
                activeRowIds={activeRowIds}
                isLoading={isLoading}
                isRestricted={isRestricted(row.node.rankingPosition)}
                index={index}
              />
            ),
          )}
        </tbody>
      </table>
    </>
  );
};
