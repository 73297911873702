import {
  SOCIAL_MEDIA_LINK_FACEBOOK,
  SOCIAL_MEDIA_LINK_GOOGLE_NEWS,
  SOCIAL_MEDIA_LINK_INSTAGRAM,
  SOCIAL_MEDIA_LINK_LINKEDIN,
  SOCIAL_MEDIA_LINK_TWITTER,
  SOCIAL_MEDIA_LINK_XING,
} from '../../../../../../constants';

export const socialIconItems = [
  {
    type: 'Facebook',
    link: SOCIAL_MEDIA_LINK_FACEBOOK,
  },
  {
    type: 'Twitter',
    link: SOCIAL_MEDIA_LINK_TWITTER,
  },
  {
    type: 'Xing',
    link: SOCIAL_MEDIA_LINK_XING,
  },
  {
    type: 'Linkedin',
    link: SOCIAL_MEDIA_LINK_LINKEDIN,
  },
  {
    type: 'Instagram',
    link: SOCIAL_MEDIA_LINK_INSTAGRAM,
  },
  {
    type: 'Google News',
    link: SOCIAL_MEDIA_LINK_GOOGLE_NEWS,
  },
];
