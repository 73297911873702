import React, { Suspense } from 'react';
import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import Picture from '../../../../../../../common/components/Picture';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import Icon from '../../../../components/Icon';
import TextParagraph from '../../../../components/Paragraphs/components/TextParagraph';
import RestrictedContent from '../../../../components/RestrictedContent';
import IconTrend from '../IconTrend';
import TrendChart from '../TrendChart';
import { apolloConfig } from '../../../../components/Router/apolloConfig';
import { STYLE_1X1_210 } from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import { TableRowProps } from './typings';
import { useStableNavigate } from '../../../../../../../shared/hooks/useStableNavigateContext';

type RowDetailsProps = {
  data: Person;
  isOpen: boolean;
};

const RankingRow = ({
  row,
  toggleRow,
  activeRowIds,
  isRestricted,
  index,
}: TableRowProps) => {
  const {
    node: {
      rankingPosition,
      rankingValue,
      rankingIndustry,
      rankingState,
      rankingTrend,
      person,
    },
  } = row;
  const personId = person?.id || '';
  const isRowOpen = activeRowIds.includes(personId);

  return (
    <>
      <tr
        onKeyDown={() => toggleRow(personId, rankingPosition)}
        onClick={() => toggleRow(personId, rankingPosition)}
        className={classNames(styles.CardWrapper, {
          [styles.IsOpen]: isRowOpen,
          [`restricted-section-${index + 1}`]: index > 2,
          [`section-${index + 1}`]: index < 3,
        })}
        role="button"
        tabIndex={0}
      >
        <td className={classNames(styles.RankingPosition, styles.TableCell)}>
          {rankingPosition && (
            <span>
              {rankingPosition}
              {(rankingTrend === 'new' || rankingTrend === 'returnee') && (
                <IconTrend
                  rankingTrend={rankingTrend}
                  addClass={
                    (rankingTrend === 'new' && styles.IconNew) ||
                    styles.IconReturnee
                  }
                />
              )}
            </span>
          )}
        </td>
        <RestrictedContent isActive={isRestricted} tag="">
          <td className={classNames(styles.RankingTitle, styles.TableCell)}>
            {person?.name || <>&ndash;</>}
          </td>
        </RestrictedContent>
        <td className={classNames(styles.AlignedLeft, styles.TableCell)}>
          <p className={styles.FieldHeader}>Vermögen</p>
          <span className={classNames(styles.FieldValue, styles.WealthWrapper)}>
            {rankingValue || <>&ndash;</>}

            {rankingTrend !== 'new' && rankingTrend !== 'returnee' && (
              <IconTrend
                rankingTrend={rankingTrend}
                addClass={styles.IconTrend}
              />
            )}
          </span>
        </td>
        <RestrictedContent isActive={isRestricted} tag="">
          <td className={classNames(styles.AlignedRight, styles.TableCell)}>
            <p className={styles.FieldHeader}>Branche</p>
            <span className={styles.FieldValue}>
              {rankingIndustry || <>&ndash;</>}
            </span>
          </td>
        </RestrictedContent>
        <td className={classNames(styles.HiddenSmDown, styles.TableCell)}>
          <p className={styles.FieldHeader}>Kanton</p>
          <span className={styles.FieldValue}>
            {rankingState || <>&ndash;</>}
          </span>
        </td>
        <td className={classNames(styles.ToggleIconWrapper, styles.TableCell)}>
          <Icon
            type="IconChevronDown"
            addClass={classNames(styles.ToggleIcon, {
              [styles.IsActive]: isRowOpen,
            })}
          />
        </td>
      </tr>
      {isRowOpen && <RowDetails data={person} isOpen={isRowOpen} />}
    </>
  );
};

const chartDataSeries = (
  person: Person,
): { x: string; y: number | string }[] => {
  if (!person || !Array.isArray(person?.rankings?.edges)) {
    return [];
  }
  const data = person.rankings.edges.map((e) => ({
    x: e?.node?.ranking?.year.toString(),
    y: isNaN(Number(e?.node?.rankingValue))
      ? e?.node?.rankingValue
      : parseInt(e?.node?.rankingValue),
  }));
  return data;
};

const RowDetails = ({ isOpen, data }: RowDetailsProps) => {
  const { teaserImage, preferredUri } = data;
  const { relativeOriginPath, focalPointX, focalPointY, alt } =
    teaserImage?.image?.file || {};
  const navigate = useStableNavigate();

  const { query, ...options } = apolloConfig.options({
    location: { pathname: preferredUri },
  });
  const [getPersonRanking, { called, data: rankingData, loading }] =
    useLazyQuery(query, options);
  if (isOpen && !called) {
    getPersonRanking();
  }
  const person: Person = rankingData?.environment?.routeByPath?.object || null;
  let chartData = [];
  if (!loading) {
    chartData = chartDataSeries(person);
  }

  return (
    <tr
      className={classNames(styles.RowDetailsOuterWrapper, {
        [styles.IsOpen]: isOpen,
      })}
    >
      <td className={styles.RowDetailsTableCell} colSpan={999}>
        <div
          className={classNames(styles.RowDetailsWrapper, {
            [styles.IsOpen]: isOpen,
          })}
        >
          <div className={styles.RowDetailsInnerWrapper}>
            <div className={styles.PictureAndMiddleWrapper}>
              <Picture
                relativeOrigin={relativeOriginPath}
                focalPointX={focalPointX}
                focalPointY={focalPointY}
                style_320={STYLE_1X1_210}
                className={styles.RankingImage}
                alt={alt || ''}
              />
              <div className={styles.MiddleWrapper}>
                <TextParagraph
                  addClass={styles.DetailsTeaserText}
                  textParagraph={{ text: data?.body }}
                />
                <div className={styles.ButtonWrapper}>
                  <ButtonWithLoading
                    ariaLabel="Show Details"
                    size="small"
                    variant="secondary"
                    addClass={styles.Button}
                    onClick={() => navigate(preferredUri)}
                  >
                    Profil anzeigen
                  </ButtonWithLoading>
                </div>
              </div>
            </div>
            <div className={classNames(styles.ChartWrapper)}>
              <div className={styles.ChartWrapperInner}>
                {!loading && isOpen && (
                  <Suspense>
                    <TrendChart
                      x={chartData.map((c) => c.x)}
                      y={chartData.map((c) => c.y as number)}
                      title="Entwicklung des Vermögens"
                    />
                  </Suspense>
                )}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RankingRow;
