export const allowedReferrers = [
  'localhost',
  // 'localhost:3333/login',
  // 'develop.cash.ch',
  // 'develop.cash.ch/login',
  // 'cash.ch',
  // 'nginx.pr-6536.cash-k8s.develop.ras.dev',
  // 'nginx.pr-6536.handelszeitung-k8s.develop.ras.dev',
  // 'www.cash.ch/login',
  'welcome.s.onelog.ch/',
  'login.s.onelog.ch',
  // 'gaa-3p-signin-demo.glitch.me',
];
