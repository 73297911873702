import React from 'react';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { assembleAkamaiImgUrl } from '../../../../../../../common/components/Picture/helpers';
import { getRestrictedClassName } from '../../../../../../../shared/helpers/withHelmet';
import locationStateSelector from '../../../../../../../shared/selectors/locationStateSelector';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import Listing from '.';
import Picture from '../../../../../../../common/components/Picture';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import EditButtons from '../../../../components/EditButtons';
import Helmet from '../../../../components/Helmet';
import MoneyhouseList from '../../../../components/MoneyhouseList';
import Paragraphs from '../../../../components/Paragraphs';
import ImageCaption from '../../../../components/Paragraphs/components/ImageCaption';
import TextParagraph from '../../../../components/Paragraphs/components/TextParagraph';
import RelatedToContent from '../../../../components/RelatedToContent';
import TeamMembers from '../../../../components/TeamMembers';
import { RESTRICTION_STATUS_PAID } from '../../../../../../../shared/constants/content';
import {
  STYLE_1X1_140,
  STYLE_1X1_210,
  STYLE_1X1_250,
} from '../../../../../../../shared/constants/images';
import {
  PUBLICATION_BIL,
  PUBLICATION_HZ,
} from '../../../../../../../shared/constants/publications';
import { LANDING_PAGE_TYPE } from '../../../LandingPage/constants';
import {
  PERSON_PAGE_SIZE,
  PERSON_PAGE_TYPE,
  RANKING_TYPE_DIGITAL_SHAPERS,
  RANKING_TYPE_RICHEST,
  RANKING_TYPE_TOP_BANKER,
  RANKING_TYPE_WHO_IS_WHO,
  RANKING_TYPE_YOUNG_RICHEST,
  RANKING_TYPE_YOUNG_RICHEST_ENTREPRENEUR,
  RANKING_TYPE_YOUNG_RICHEST_INHERITANCE,
  RANKING_TYPE_YOUNG_RICHEST_STARS,
  SCREEN_LABEL,
} from '../../constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import { PersonProps } from '../../typings';

type PersonPropsInner = PersonProps;

const filterList = (edges, rankingType) =>
  edges.filter(({ node }) => {
    return node?.ranking?.rankingType === rankingType;
  });

const PersonDetail = ({ person, location }: PersonPropsInner) => {
  const clientUrl = useSelector(
    (state) => locationStateSelector(state).clientUrl,
  );

  if (!person || !person.name) {
    return null;
  }

  const isBIL = person.publication === PUBLICATION_BIL;
  const isHZ = person.publication === PUBLICATION_HZ;

  const heroImage =
    (Array.isArray(person?.heroImageBody) &&
      person?.heroImageBody.length > 0 &&
      // @ts-ignore wrong paragraphInterface typing in autogenerated typings
      person.heroImageBody[0]?.image?.file) ||
    null;

  // hide the image, if the relativeOriginPath contains a fallback image
  const isHeadless =
    (heroImage?.relativeOriginPath &&
      heroImage?.relativeOriginPath.includes('handelszeitung_fallback', 1)) ||
    (heroImage?.relativeOriginPath &&
      heroImage?.relativeOriginPath.includes('beobachter-fallback', 1)) ||
    false;

  const organizationPositions = [];
  const personPositionsEdges =
    (Array.isArray(person?.personPositions?.edges) &&
      person.personPositions.edges.length > 0 &&
      person?.personPositions?.edges) ||
    null;

  const title = `mehr zu: ${person.name}` || 'mehr zu dieser Person';

  let mostRichList = [];
  let topBankerList = [];
  let whoIsWhoList = [];
  let digitalShapersList = [];
  let youngRichestList = [];
  let youngRichestStarsList = [];
  let youngRichestInheritanceList = [];
  let youngRichestEnterpreneurList = [];

  if (person.rankings !== null) {
    mostRichList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_RICHEST,
    );
    topBankerList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_TOP_BANKER,
    );
    whoIsWhoList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_WHO_IS_WHO,
    );
    digitalShapersList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_DIGITAL_SHAPERS,
    );
    youngRichestList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_YOUNG_RICHEST,
    );
    youngRichestStarsList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_YOUNG_RICHEST_STARS,
    );
    youngRichestInheritanceList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_YOUNG_RICHEST_INHERITANCE,
    );
    youngRichestEnterpreneurList = filterList(
      person?.rankings?.edges || [],
      RANKING_TYPE_YOUNG_RICHEST_ENTREPRENEUR,
    );
  }
  const hasRanking =
    mostRichList.length > 0 ||
    topBankerList.length > 0 ||
    whoIsWhoList.length > 0 ||
    digitalShapersList.length > 0 ||
    youngRichestList.length > 0 ||
    youngRichestStarsList.length > 0 ||
    youngRichestInheritanceList.length > 0 ||
    youngRichestEnterpreneurList.length > 0;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: person?.firstName || '',
    image:
      (heroImage?.relativeOriginPath &&
        assembleAkamaiImgUrl({
          relativeOriginPath: heroImage?.relativeOriginPath,
          width: 350,
          height: 350,
          focalPointX: heroImage?.focalPointX,
          focalPointY: heroImage?.focalPointY,
          clientUrl,
        })) ||
      '',
    brand: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name:
        (personPositionsEdges &&
          personPositionsEdges.map((item) => item.node.organization.title)) ||
        '',
    },

    jobTitle:
      (personPositionsEdges &&
        personPositionsEdges.map((item) => item.node.position)) ||
      '',

    sameAs: person?.moneyhousePreferredUri || '',
    isAccessibleForFree: true,
    hasPart: undefined,
  };

  if ([RESTRICTION_STATUS_PAID].includes(person?.restrictionStatus)) {
    jsonLd.isAccessibleForFree = false;

    jsonLd.hasPart = [
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: `.${getRestrictedClassName(person?.__typename)}`,
      },
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: '.paywall-person-text',
      },
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: '.paywall-person-paragraphs',
      },
      {
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: '.paywall-person-listing',
      },
    ];
  }

  const script = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonLd),
    },
  ];

  if (personPositionsEdges) {
    for (let i = 0; i < personPositionsEdges.length; ++i) {
      organizationPositions.push(
        personPositionsEdges[i].node?.organization?.organizationPositions,
      );
    }
  }

  return (
    <div className={classNames(SCREEN_LABEL, styles.Wrapper)}>
      <EditButtons
        editContentUri={person.editContentUri}
        editRelationUri={person.editRelationUri}
        cloneContentUri={person.cloneContentUri}
      />
      <Helmet script={(person?.moneyhousePreferredUri && script) || []} />
      <div
        className={classNames(styles.OuterWrapper, {
          [styles.OuterWrapperNoImage]:
            !isHeadless && !heroImage?.relativeOriginPath,
        })}
      >
        <div className={styles.HeaderBackground} />
        <div className={classNames(sections.Container, styles.InnerWrapper)}>
          <Breadcrumbs
            pageUrl={person.preferredUri}
            items={person.activeMenuTrail}
          />
          <div
            className={classNames(styles.PersonDataWrapper, {
              [styles.HasNoImage]:
                !isHeadless && !heroImage?.relativeOriginPath,
            })}
          >
            {!isHeadless && heroImage?.relativeOriginPath && (
              <div className={styles.CaptionWrapper}>
                <Picture
                  relativeOrigin={heroImage?.relativeOriginPath}
                  focalPointX={heroImage?.focalPointX}
                  focalPointY={heroImage?.focalPointY}
                  className={styles.Image}
                  alt={heroImage?.alt || ''}
                  style_320={STYLE_1X1_140}
                  style_760={STYLE_1X1_210}
                  style_960={STYLE_1X1_250}
                />
                <div className={styles.Caption}>
                  <ImageCaption
                    key={`image-caption-item-${
                      (Array.isArray(person?.heroImageBody) &&
                        person?.heroImageBody.length > 0 &&
                        // @ts-ignore wrong paragraphInterface typing in autogenerated typings
                        person.heroImageBody[0]?.image?.id) ||
                      ''
                    }`}
                    suppressSource={
                      (Array.isArray(person?.heroImageBody) &&
                        person?.heroImageBody.length > 0 &&
                        // @ts-ignore wrong paragraphInterface typing in autogenerated typings
                        person.heroImageBody[0]?.suppressSource) ||
                      false
                    }
                    credit={
                      (Array.isArray(person?.heroImageBody) &&
                        person?.heroImageBody.length > 0 &&
                        // @ts-ignore wrong paragraphInterface typing in autogenerated typings
                        person.heroImageBody[0]?.image?.credit) ||
                      ''
                    }
                    caption=""
                  />
                </div>
              </div>
            )}

            <div className={styles.PersonDataCell}>
              <h1 className={styles.Title}>
                <span className={styles.ShortTitle}>Person</span>
                {person.name || ''}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          sections.Container,
          getRestrictedClassName(person.__typename),
        )}
      >
        {isHZ && <MoneyhouseList person={person} />}
        {isHZ &&
          organizationPositions &&
          organizationPositions.map((position, index) => {
            return (
              <TeamMembers
                key={`do-render-team-members-${index + 1}`}
                persons={position}
                itemsCount={6}
                origin={PERSON_PAGE_TYPE}
                title={person.name}
              />
            );
          })}

        {isBIL && typeof person.text !== 'undefined' && (
          <TextParagraph
            addClass={classNames(
              'paywall-person-text',
              styles.ParagraphsContainer,
            )}
            textParagraph={{ text: person.text }}
          />
        )}
        {isBIL && (
          <div
            className={classNames(
              'paywall-person-paragraphs',
              styles.ParagraphsContainer,
            )}
          >
            <Paragraphs
              pageBody={person.content}
              colStyle={grid.ColXs24}
              origin={LANDING_PAGE_TYPE}
            />
          </div>
        )}
      </div>
      <div className={classNames(getRestrictedClassName(person.__typename))}>
        {isBIL && hasRanking && (
          <div className="paywall-person-listing-title">
            <h2 className={classNames(grid.Container, styles.ListHeaderTitle)}>
              {person.name}
              &nbsp;in Listen
            </h2>
            <div className={styles.ListWrapper}>
              {mostRichList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={mostRichList}
                  rankingType={RANKING_TYPE_RICHEST}
                />
              )}
              {topBankerList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={topBankerList}
                  rankingType={RANKING_TYPE_TOP_BANKER}
                />
              )}
              {whoIsWhoList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={whoIsWhoList}
                  rankingType={RANKING_TYPE_WHO_IS_WHO}
                />
              )}
              {digitalShapersList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={digitalShapersList}
                  rankingType={RANKING_TYPE_DIGITAL_SHAPERS}
                />
              )}
              {youngRichestList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={youngRichestList}
                  rankingType={RANKING_TYPE_YOUNG_RICHEST}
                />
              )}
              {youngRichestStarsList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={youngRichestStarsList}
                  rankingType={RANKING_TYPE_YOUNG_RICHEST_STARS}
                />
              )}
              {youngRichestInheritanceList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={youngRichestInheritanceList}
                  rankingType={RANKING_TYPE_YOUNG_RICHEST_INHERITANCE}
                />
              )}
              {youngRichestEnterpreneurList.length > 0 && (
                <Listing
                  addClass="paywall-person-listing"
                  listing={youngRichestEnterpreneurList}
                  rankingType={RANKING_TYPE_YOUNG_RICHEST_ENTREPRENEUR}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <RelatedToContent
        title={person.name}
        relatedToTitle={title}
        location={location}
      />
    </div>
  );
};

const PersonWrapper = compose(
  withHelmet({
    getNode: (mapProps) => mapProps.person || null,
    getNodesCount: (mapProps) => mapProps.person?.personArticles?.count || 0,
    pageSize: PERSON_PAGE_SIZE,
  }),
)(PersonDetail);

export default PersonWrapper;
