/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { EDITORIALS_PICKS_QUERY } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const { contentTypes, publication, title, additionalPublications } = params;
    return {
      query: EDITORIALS_PICKS_QUERY,
      variables: {
        contentTypes,
        publication,
        additionalPublications,
        title,
      },
      skip: !title,
    };
  },
};
