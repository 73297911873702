import React, { ReactElement } from 'react';
import ButtonWithLoading from '../../../ButtonWithLoading';
import { LazyLoadingProps } from './typings';

type LazyLoadingPropsInner = LazyLoadingProps;

const LazyLoading = (props: LazyLoadingPropsInner): ReactElement | null => {
  // hide "load more" button if no further items are fetchable
  if (!props.hasMoreResults) {
    return null;
  }

  return (
    <div data-testid="LazyLoading-wrapper">
      <ButtonWithLoading
        ariaLabel="mehr laden"
        onClick={!props.isLoading ? props.loadMore : null}
        iconTypeLeft="IconArrowRotateRight"
        loading={props.isLoading}
      >
        {props.children || (
          <span data-testid="Lazy-Loading-load-more-wrapper"> Mehr laden</span>
        )}
      </ButtonWithLoading>
    </div>
  );
};

export default LazyLoading;
