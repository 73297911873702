import React from 'react';
import classNames from 'classnames';
import Icon from '../../../../components/Icon';
import { ReactComponent as NewIcon } from '../../../../components/SVGIcon/assets/new-in-list.svg';
import styles from './styles.legacy.css';
// @ts-ignore

export const IconTrend = ({
  rankingTrend,
  addClass = null,
}: {
  rankingTrend: string;
  addClass?: string;
}) => {
  if (rankingTrend === null) {
    return null;
  }

  let iconType = 'IconArrowRight';
  let iconClass = null;

  if (rankingTrend === 'up') {
    iconType = 'IconArrowUpRight';
    iconClass = styles.Positive;
  }

  if (rankingTrend === 'down') {
    iconType = 'IconArrowDownRight';
    iconClass = styles.Negative;
  }

  if (rankingTrend === 'returnee') {
    iconType = 'IconArrowRotateLeft';
    iconClass = styles.IconReturnee;
  }

  if (rankingTrend === 'new') {
    return (
      // @ts-ignore
      <NewIcon
        className={classNames(styles.IconNew, { [addClass]: !!addClass })}
      />
    );
  }

  return (
    <Icon
      addClass={classNames(styles.TrendIcon, iconClass, {
        [addClass]: !!addClass,
      })}
      type={iconType}
    />
  );
};

export default IconTrend;
