import { SocialMediaBarIconProps } from '../../../../../../../common/components/SocialMediaBar/typings';

const SOCIAL_MEDIA_LINK_LINKEDIN =
  'https://www.linkedin.com/company/hz-insurance/';

export const socialMediaItems: SocialMediaBarIconProps[] = [
  {
    type: 'Linkedin',
    link: SOCIAL_MEDIA_LINK_LINKEDIN,
  },
];
